import React, { Component } from 'react';
import { connect } from 'react-redux';
import Stack from '@mui/material/Stack';
import Button from './common/ButtonComponent.js';
import Radio from '@mui/material/Radio';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import ConfirmationForm from 'containers/confirmation/confirmation_form';
import {
  getTransactionDetails,
  getMessageToShow,
  submitForSelfScheduling,
  notifyCustomerService,
  getUTCTime,
  getBannerMaintenanceAlert
} from 'actions/index';
import { bindActionCreators } from 'redux';
import { TrackingSpinner } from 'containers/common/tracking_spinner';
import {
  phoneNoFormat,
  clearSessionStorage,
  getTransactionRef
} from 'containers/common/innovel_util';
import InnovelFlashMessage from 'containers/common/innovel_flash_message.js';
import LogoffLayer from './logoffLayer';
import TimeWindowTimer from './timeWindowTimer';
import moment from 'moment';
import { TimerExpirationModal } from 'containers/common/timerExpirationModal.js';
import parse from 'html-react-parser';

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.confScrollView = React.createRef();
    this.state = {
      openModal: false,
      alternatePhone: null,
      loading: false,
      orderValid: null,
      deliveryDetailsCorrect: false,
      schedulingOrder: false,
      scrollToInnovelFlashMsg: false,
      showErrorMessage: false,
      errorMessage: '',
      btnDisable: false,
      alternateDisabled: false,
      infoQuestionAnswered: false,
      disableBtn: false,
      timeWindowEligible: false,
      dceWindowsFound: false,
      twTimerExpired: false
    };
    const transID = getTransactionRef();
    if (transID === null) {
      this.state = {
        transactionID: ''
      };
    } else {
      this.state = {
        transactionID: transID
      };
    }
  }

  componentDidMount() {
    this.props.getBannerMaintenanceAlert();
    document.title = "Schedule My Delivery or Return Pickup | Costco Logistics"
    //Close the modal on Esc button press
    this.setState({showErrorMessage: false, errorMessage: '', errorState: false});
    this.confScrollView.current.scrollIntoView();

    if (
      this.state.transactionID === '' ||
      this.state.transactionID === undefined ||
      this.state.transactionID === null
    ) {
      // this.setState({
      //   loading: false,
      //   schedulingOrder: false,
      //   deliveryDetailsCorrect: false,
      //   alternateDisabled: true,
      //   errorState: true,
      //   errorMessage: 'We are unable to schedule your order online. Contact us at 1-800-955-2292 to schedule your delivery.',
      //   showErrorMessage: true
      // });
      this.props.history.push('/userselfschedule');
    } else {
      this.setState({ loading: true, orderValid: null });

      this.props.getUTCTime().then(result => {
        const httpStatus = result.payload.status
        if(httpStatus === 200) {
          //var response = result.payload.data;
          sessionStorage.setItem("utcTime", result.payload.request.response)
        }
        else {
          sessionStorage.removeItem("utcTime");
        }
        //make service call after time api call
        this.props
        .getTransactionDetails(this.state.transactionID, 3)
        .then(result => {
          const httpStatus = result.error === undefined ? result.payload.status : result.payload.response !== undefined ? result.payload.response.status : result.payload.request.status;
          if (httpStatus === 200 || httpStatus === 400 || httpStatus === 500) {
            const status = result.error === undefined ? result.payload.data.status : result.payload.response.data.status;
            if(status !== null || status !== undefined) {
              const validStatusCode = status.statusCode != null ? "True" : "False";
              if(status.statusCode === '00' && validStatusCode) {
                let transactionDetails = result.payload.data.serviceResponse;

                //Set the TW timer values
                if(transactionDetails.timeWindowFlag) {
                  if(localStorage.getItem("timerStartTime") && localStorage.getItem("dceWindows")) {
                    this.setState({ dceWindowsFound: localStorage.getItem("dceWindows")});
                    //Match the TW load time and user current time
                    if(JSON.parse(localStorage.getItem("dceWindows")) === true &&
                      moment.utc(Date.now()).diff(localStorage.getItem("timerStartTime"), 'minutes', true) > 3.05) {
                        this.setState({ twTimerExpired: true, dceWindowsFound: false });
                    }
                  } else {
                    if(localStorage.getItem("dceWindows") && JSON.parse(localStorage.getItem("dceWindows")) === false) {
                      this.setState({ dceWindowsFound: false});
                    } else {
                      this.props.history.push('/userselfschedule?timeoutWindow=True');
                    } 
                  }
                }
                
                //set client name and phone for customer support message
                this.setState({clientCode: transactionDetails.workOrderList[0].trackingOrderList[0].clientCode});
                this.setState({mscPhone: transactionDetails.workOrderList[0].trackingOrderList[0].custService});
                this.setState({loading: false, orderValid: true, stepNum: 3, btnDisable: false, deliveryDetailsCorrect: true, alternateDisabled: false, resetForm: true, spinnerActive: false});
                //check if order is time window eligible to restart timer
                this.setState({ timeWindowEligible: transactionDetails.timeWindowFlag });
              }
              else {
                this.setState({
                  loading: false,
                  orderValid: false,
                  errorState: true
                });
                const errorMessage = result.error === undefined ? result.payload.data.errorDetails.errors[0].message : result.payload.response.data.errorDetails.errors[0].message;
                let messageObj = {
                  alertType: 'error',
                  message: parse(errorMessage)
                };
                this.props.getMessageToShow(messageObj);
              }
            }
          } else {
            this.setState({
              loading: false,
              orderValid: false,
              errorState: true
            });

            let messageObj = {
              alertType: 'error',
              message: process.env.REACT_APP_MSC_CONTACT_US
            };
            this.props.getMessageToShow(messageObj);
          }
        }).catch(error => {
          this.setState({
            schedulingOrder: false,
            deliveryDetailsCorrect: false,
            alternateDisabled: true,
            errorState: true,
            errorMessage: process.env.REACT_APP_MSC_CONTACT_US,
            showErrorMessage: true
          });
        });
      });       
    }
  }

  acceptMethods = storeConfirmationDetails => {
    // Parent stores the method that the child passed
    this.storeConfirmationDetails = storeConfirmationDetails;
  };

  submitClickValues = () => {
    this.props.getUTCTime().then(result => {
      const httpStatus = result.payload.status
      if(httpStatus === 200) {
        //var response = result.payload.data;
        sessionStorage.setItem("utcTime", result.payload.request.response)
      }
      else {
        sessionStorage.removeItem("utcTime");
      }  
      this.next()
    }).catch(error => {
      sessionStorage.removeItem("utcTime");
      this.next()
    }); 
  }

  handleClick = () => {
    if(this.props.transactionDetails.timeWindowFlag) {
      if(localStorage.getItem("dceWindows") && localStorage.getItem("timerStartTime")) {
        if(JSON.parse(localStorage.getItem("dceWindows")) === true && 
        moment.utc(Date.now()).diff(localStorage.getItem("timerStartTime"), 'minutes', true) > 3.05) {
          this.setState({ twTimerExpired: true, dceWindowsFound: false });
        } else {
          this.submitClickValues();
        }
      } else {
        if(localStorage.getItem("dceWindows") && JSON.parse(localStorage.getItem("dceWindows")) === false) {
          this.submitClickValues();
        } else {
          this.props.history.push('/userselfschedule?timeoutWindow=True');
        }  
      }
    } else {
      this.submitClickValues();
    }
  }

  next = e => {
    // To avoid multiple button clicks
    if(this.state.disableBtn) {
      return
    }
    this.state.disableBtn = true;
  
    // Code Execution
    this.setState({
      loading: false,
      schedulingOrder: true,
      scrollToInnovelFlashMsg: false,
      spinnerActive: true
    });
    return (
      this.storeConfirmationDetails()
        .catch(error => {
          this.setState({
            schedulingOrder: false,
            deliveryDetailsCorrect: false,
            alternateDisabled: false,
            errorMessage: process.env.REACT_APP_MSC_CONTACT_US,
            showErrorMessage: true,
            spinnerActive: false,
            disableBtn: false
          });
        }).finally(() => {
          this.setState({
            deliveryDetailsCorrect: false,
            alternateDisabled: false,
            spinnerActive: false,
            disableBtn: false
          });
        })      
    );
  };

  setDeliveryDetailsCorrectFlag = () => {    
    this.props.getMessageToShow({message:'', alertType:null});
    this.setState({
      rbYes: true,
      rbNo: false,
      btnDisable: false,
      deliveryDetailsCorrect: true,
      alternateDisabled: false,
      scrollToInnovelFlashMsg: false,
      errorMessage: '',
      showErrorMessage: false,
      infoQuestionAnswered: true
    });
  };

  setDeliveryDetailsIncorrectFlag = (e) => {
    this.props.getMessageToShow({message:'', alertType:null});
    this.setState({
      rbNo: true,
      rbYes: false,
      loading: false,
      btnDisable: false,
      deliveryDetailsCorrect: false,
      alternateDisabled: false,
      scrollToInnovelFlashMsg: false,
      errorMessage: '',
      showErrorMessage: true,
      infoQuestionAnswered: true
    });

    e.target.checked = true;
  };

  checkDeliveryDetailsFlag = () => {
    this.setState({
      loading: false,
      scrollToInnovelFlashMsg: false,
      errorMessage: 'Confirm your information is correct before submitting your request.',
      showErrorMessage: true,
      infoQuestionAnswered: false,
      disableBtn: false
    });
  }

  InfoIncorrectOk = () => {
    if (
      this.state.transactionID === '' ||
      this.state.transactionID === undefined ||
      this.state.transactionID === null
    ) {
      this.props.history.push('/');
    }

    this.setState({ openModal: false, disableBtn: false }, () => {
      const transRef = {
        transactionRef: this.state.transactionID
      };
      // this.props.notifyCustomerService(transRef).then(result => {
      //   clearSessionStorage();
      //   this.props.history.push('/');
      // }).catch(error => {
      //   clearSessionStorage();
      //   this.props.history.push('/');
      // });
      clearSessionStorage();
      this.props.history.push('/');
    });
  };

  enableButton = flag => {
    this.setState({
      isButtonEnabled: flag
    });
  };

  resetForm = flag => {
    this.setState({      
      resetForm: flag
    });

    if(this.state.resetForm) {
      this.setState({
        rbYes: false,
        rbNo: false,
        showErrorMessage: false,
        infoQuestionAnswered: false
      })
    }
  }

  handleSpinner = flag => {
    this.setState({      
      spinnerActive: flag
    });
  }

  handleDisableBtn = flag => {
    this.setState({      
      disableBtn: flag
    });
  }

  closeExpirationModal = () => {
    clearSessionStorage();
    this.props.history.push('/userselfschedule?timeoutWindow=True');
  }

  scrollToBottom = () => {
    if (this.messagesEnd !== undefined && this.messagesEnd !== null) {
      this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
    }
  };

  componentDidUpdate() {
    this.scrollToBottom();
  }

  render() {
    return (
      <div className="container-fluid main-grid" style={{ minHeight: '90vh' }} ref={this.confScrollView} role="main">
        {!this.state.twTimerExpired && <LogoffLayer />}
        {this.state.twTimerExpired && 
          <TimerExpirationModal 
          timeOutType={"Scheduling"}
          showModal={true}
          startingTime={moment(localStorage.getItem("timerStartTime")).format("h:mm a") }
          handleClose={this.closeExpirationModal}
        />}
        {this.state.timeWindowEligible && JSON.parse(this.state.dceWindowsFound) && !this.state.twTimerExpired ? <TimeWindowTimer /> : ""}
        <div className="row">
          <div className="col-lg-10 col-md-8 offset-sm-1 offset-md-2 offset-lg-2">
            <div id="mains" className="sr-only" tabIndex="-1"></div>
            <div id="banner" aria-labelledby='banner'>
                <h1 className="t1">
                  Step 3 of 3 - Review Information
                </h1>
                {' '}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-xl-4 col-sm-6 offset-sm-1 offset-md-2 offset-lg-2 result-ele">
            {this.state.orderValid && !this.state.loading && 
              (<ConfirmationForm
              enableButton={this.enableButton}
              responseValues={this.props.transactionDetails}
              history={this.props.history}
              shareMethods={this.acceptMethods.bind(this)}
              deliveryDetailsCorrect={this.state.deliveryDetailsCorrect}
              alternateDisabled={this.state.alternateDisabled}
              resetForm={this.resetForm}
              activeSpinner={this.handleSpinner}
              disableBtn={this.handleDisableBtn}
            />)}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 offset-sm-1 offset-md-2 offset-lg-2 result-ele">
            {this.state.orderValid && !this.state.loading && (
              <div>
                <fieldset>
                  <legend id="correct-info-label" style={{fontSize: "1rem" }}>Does your information look correct?</legend>
                  <FormControl>
                    <RadioGroup>
                      <FormControlLabel
                        checked={this.state.rbYes}
                        value="True"
                        control={<Radio size="small" style={{ paddindTop: "0", paddingBottom: "5" }} />}
                        label="Yes"
                        onChange={this.setDeliveryDetailsCorrectFlag}
                      />
                      <FormControlLabel
                        checked={this.state.rbNo}
                        value="False"
                        control={<Radio size="small" style={{ paddingTop: "0", paddingBottom: "0" }} />}
                        label="No"
                        onChange={this.setDeliveryDetailsIncorrectFlag}
                      />
                    </RadioGroup>
                  </FormControl>
                </fieldset>
              </div>
            )}
          </div>
        </div>   
        <div className="row">
          <div className="col-lg-4 offset-md-2 offset-lg-2">
            {this.state.loading && (
              <div style={{ marginTop: '10vh', width: '100%', justifyContent: 'center' }} ref={this.topReference}>
                <div className="centerAlign" style={{ flexDirection: 'column' }}>
                  {<TrackingSpinner />}
                </div>
              </div>
            )}
          </div>
        </div>   
        <div className="row">
          <div className='col-lg-9 col-md-9 col-sm-10 offset-sm-1 offset-md-2 offset-lg-2 traking-ele'>
              <div style={{marginTop: this.state.errorState ? "10px" : "0px"}}><InnovelFlashMessage /></div>
              {this.state.showErrorMessage && (
                <div id="noSchedule" role="alert" tabIndex="-1" className="err-msg" style={{float: "left", width: "100%"}}>
                  <p>
                    {this.state.errorMessage || <div>If the address on the order is not correct <a href="https://customerservice.costco.com/app/answers/detail/a_id/8162/~/give-us-a-call" style={{textDecoration: "underline", color: "#0060A9"}} target="_blank" rel='noopener noreferrer' aria-label='Contact Us'>Contact Us</a>. We are unable to complete scheduling until the address is corrected.</div>}
                  </p>
                </div>
              )}
          </div>
        </div> 
        <div className="row">
          <div className="col-lg-9 col-xl-8 col-md-9 col-sm-11 col-xs-12 offset-md-2">
            {this.state.orderValid && !this.state.loading && (
              <div>
                <Stack 
                  direction={{ xs: 'column-reverse', sm: 'row', md: 'row', lg: 'row' }} 
                  spacing={{ xs: 2, sm: 2, md: 4, lg: 4 }} 
                  justifyContent={{xs: 'center', sm: 'right', md: 'right', lg: 'right'}}
                  alignItems={{xs: 'center', sm: 'right', md: 'right', lg: 'right'}}
                  fullWidth={{xs: 'true', sm: 'true'}}
                  padding={{lg: 3}}
                >
                  <Link
                    href="tracking_measure" 
                    underline="hover"
                    sx={{color: '#0060A9'}}
                  >
                    Back
                  </Link>
                  <Button
                    disableRipple
                    role="link"
                    variant="contained"
                    sx={{backgroundColor: '#3071a9', minWidth: 265, maxWidth: 400, textTransform: 'capitalize', fontSize: "16px"}}
                    disabled={this.state.btnDisable}
                    onClick={this.state.infoQuestionAnswered ? this.state.deliveryDetailsCorrect ? this.handleClick : () => this.InfoIncorrectOk() : this.checkDeliveryDetailsFlag}
                    btnText={this.state.infoQuestionAnswered ? this.state.deliveryDetailsCorrect ? 'Submit Request' : 'Cancel Request' : 'Submit Request'}
                  />
                  {this.state.spinnerActive &&
                    <div>
                      <TrackingSpinner />
                    </div>
                  }
                </Stack>
              </div>
            )}
            <br role="presentation" /><br role="presentation"/>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactionDetails: state.transactionDetails,
    selfScheduleResponse: state.selfScheduleResponse,
    scheduleIncorrectInfoResponse: state.scheduleIncorrectInfoResponse
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getTransactionDetails,
        getMessageToShow,
        submitForSelfScheduling,
        notifyCustomerService,
        getUTCTime,
        getBannerMaintenanceAlert
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);

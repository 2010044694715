import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'styles/_font.scss';
import 'styles/_landing.scss';
import TruckLogo from 'images/truck_costco_scheduler.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getOrderServiceMaintenanceAlert
} from 'actions/index';
import InnovelServiceAlert from 'containers/common/innovel_service_alert.js';
import $ from 'jquery';

class Home extends Component {
  constructor () {
    super();
    this.state = {
      showMsg: false,
      homeError: ''
    }
  }
  componentDidMount() {
    this.props.getOrderServiceMaintenanceAlert();
    this.scrollToDiv();
    
    if(localStorage.getItem(`homeError`)){
      let msgVal = localStorage.getItem(`homeError`);
      this.setState({homeError: localStorage.getItem(`homeError`)});
      if(msgVal === `null`){
        this.setState({ showMsg: true});
      }
    }
    localStorage.clear();
  }

  scrollToDiv() {
    var divId = window.location.hash;
    if (
      divId !== '' &&
      divId !== undefined &&
      $(divId).offset() !== undefined
    ) {
      $('html, body').animate(
        {
          scrollTop: $(divId).offset().top - 100
        },
        2000
      );
    }
  }

  render() {
    const messObj = this.props.messageObj;
    this.scrollToDiv();
    return (
      <div className="Home" style={{ paddingTop: '130px', minHeight: '90vh' }}>
        <div className="centerAlign truckImg">
        <div id="mains" className="sr-only" tabIndex="-1"> </div>
          <img src={TruckLogo} className="img-fluid" alt="Costco Wholesale Logistics Delivery Truck"/>
        </div>
        <div className="centerAlign">
          <InnovelServiceAlert messObj={messObj} name='tracking' />
        </div>
        <div className="dce-error">
        {this.state.showMsg && (
          <div id="norecords" role="alert" tabIndex="-1" className="err-msg">
            <p style={{ textAlign: `center`}} className="hi">
              {this.state.homeError}
            </p>
          </div>
        )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    messageObj: state.maintenanceAlertMessage.maintenanceMessage
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { getOrderServiceMaintenanceAlert },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

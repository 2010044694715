import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import 'styles/_deliveryform.scss';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'styles/_font.scss';
import {
  updateData,
  getMessageToShow
} from 'actions/index';

export class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: props.qa.answer,
      haulaway: props.qa.question.semanticType === 'HAUL_AWAY_BULK'
    };
    this.errorRef = React.createRef();
  }

  componentDidMount() {
    let newAns = {}
    if (this.state.haulaway) {
      newAns = this.props.qa.answer.map(ans => {
        ans.answer.newValue = ans.answer.currentValue;
        return { ...ans }
      });
    } else {
      newAns = this.props.qa.answer.map(ans => {
        ans.newValue = ans.currentValue;
        return { ...ans }
      });
    }
    this.props.updateData(newAns, this.props.qa.question.id, this.props.qa.question.semanticType);
    this.setState({ answer: newAns });
  }

  handleCheckBoxChange = number => {
    let newAnswer = this.state.answer.map((answer) => {
      const trackingNumber = this.state.haulaway ? answer.answer.trackingNumber : answer.trackingNumber;
      if (trackingNumber === number) {
        if (this.state.haulaway) {
          if (answer.answer.newValue === true || answer.answer.newValue === 'true') {
            answer.answer.newValue = false;
          } else {
            answer.answer.newValue = true;
          }
        }
        else {
          if (answer.newValue === true || answer.newValue === 'true') {
            answer.newValue = false;
          } else {
            answer.newValue = true;
          }
        }
      }
      return answer;
    });
    this.props.clearErrorId(this.props.qa.question.id)
    this.props.updateData(newAnswer, this.props.qa.question.id, this.props.qa.question.semanticType);
    this.setState({ answer: newAnswer });
  };

  render() {
    const haulaway = this.state.haulaway;
    const { errorIDs = [] } = this.props;
    const error = _.find(errorIDs, ['id', this.props.qa.question.id]);
    if(error && error.rank == errorIDs[0].rank && this.errorRef.current !== null) {
      this.errorRef.current.focus();
    }
    const orderTrackingMap = {};
    this.props.qa.answer.forEach(ans => {
      const workOrderNumber = ans.workOrderNumber  || 'Not Available';
      if (orderTrackingMap[workOrderNumber]) {
        orderTrackingMap[workOrderNumber].trackingNumberList.push(ans)
      } else {
        orderTrackingMap[workOrderNumber] = {
          trackingNumberList: [ans]
        }
      }
    });
    return (
      <div>
        <fieldset>
          <legend style={{fontStyle: "normal", fontSize: "14px", lineHeight: "20px"}} className="text-xxsmall">
            <span title={this.props.qa.question.tooltip}>{this.props.qa.question.question}</span>
            {this.props.qa.question.note && <p>{this.props.qa.question.note}</p>}
          </legend>
          {error && (
            <div className="err-msg-inbox orderBoxWidth">
              At least 1 tracking number must be selected.
            </div>
          )}
          {Object.keys(orderTrackingMap).map((workOrderNumber) => {
            const { trackingNumberList } = orderTrackingMap[workOrderNumber];
            return (
              <div className='orderBoxWidth' style={{ border: '1px solid #909090', paddingBottom: '16px', marginBottom: '16px' }}>
                <div className='container-fluid' style={{ display: workOrderNumber === 'Not Available' ? 'none' : 'block' }}>
                  <h4 style={{ alignSelf: 'flex-start' }} className="text mt-3">
                    Order Number
                  </h4>
                  <span style={{ color: '#333333' }}>{workOrderNumber}</span>
                </div>

                {trackingNumberList.map((answer, index) => {
                  const value = haulaway ? answer.answer.newValue : answer.newValue;
                  const trackingNumber = haulaway ? answer.answer.trackingNumber : answer.trackingNumber;
                  return (
                    <div key={trackingNumber} className="container-fluid mt-3">
                      <div className="row">
                        <div className={"col checkbox-row " + (value ? "checked" : "unchecked")} id={trackingNumber}>
                          <Stack
                            direction="row"
                            justifyContent="left"
                            alignItems="center"
                            spacing={{ xs: 0, sm: 2, md: 2, lg: 2 }}
                          >
                            <div>
                              <input
                                id={"check_" + (index + 1)}
                                ref={this.errorRef}
                                style={{ verticalAlign: 'bottom' }}
                                className="largeCheckbox"
                                type="checkbox"
                                checked={value}
                                onChange={() => { this.handleCheckBoxChange(trackingNumber) }}
                              />
                            </div>
                            <label for={"check_" + (index + 1)}>
                              <span style={{margin: "0px"}}>Tracking Number&nbsp;</span><br role="presentation" />
                              <span style={{ margin: "0px" }}>{trackingNumber}</span>
                            </label>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  );
                })
                }
              </div>
            )
          })
          }
        </fieldset>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { updateData, getMessageToShow },
      dispatch
    )
  };
};

export default connect(null, mapDispatchToProps)(CheckBox);

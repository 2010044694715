import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import 'styles/_deliveryform.scss';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'styles/_font.scss';
import { updateData, getMessageToShow } from 'actions/index';
import TextField from '@mui/material/TextField';
import { RegexMatch } from 'containers/common/innovel_util';
import { SEMANTIC_TYPES } from 'constants.js';
import { QuestionLabels } from 'containers/common/innovel_util';

export class StringInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stringInput: props.qa.answer.newValue || '',
      labels: QuestionLabels(SEMANTIC_TYPES, this.props.qa.question.semanticType)
    };
    this.errorRef = React.createRef();
  }

  handleStringChange = e => {
    // this.props.clearErrorId(this.props.qa.question.id)
    if (this.props.qa.question.semanticType === 'GATE_PASS_CODE') {
      const gatePassRegex = /^[0-9#*]*$/;
      if (!gatePassRegex.test(e.target.value)) {
        return;
      }
    } else if (this.props.qa.question.semanticType === 'NEWLY_BUILT') {
      if (RegexMatch(e.target.value, 2)) {
        return;
      }
    }
    this.props.updateData(
      e.target.value,
      this.props.qa.question.id,
      this.props.qa.question.semanticType
    );
    this.setState({ stringInput: e.target.value });
  };

  handleError = e => {
    const { errorIDs = [] } = this.props;
    const error = _.find(errorIDs, ['id', this.props.qa.question.id]);
    if(e.target.value !== "") {
      this.props.clearErrorId(this.props.qa.question.id)
    }
  }

  render() {
    let inputLength = null;
    const { errorIDs = [] } = this.props;
    const error = _.find(errorIDs, ['id', this.props.qa.question.id]);
    var errorFound = false;
    if(error) {
      var errorFound = true;
      if(error.rank == errorIDs[0].rank && this.errorRef.current !== null) {
        this.errorRef.current.focus();
      }
    }
    if (this.props.qa.question.semanticType === 'GATE_PASS_CODE') {
      inputLength = 10;
    } else if(this.props.qa.question.semanticType === 'NEWLY_BUILT') {
      inputLength = 50;
    }
    const label = this.props.qa.question.semanticType === 'GATE_PASS_CODE' ? 'Gate Code Required' : 'Street Names';
    const errorId = error ? " " + this.state.labels.errorId : "";
    return (
      <div style={{marginBottom: "18px", marginTop: "15px"}}>
        <label className="text-xxsmall" style={{marginBottom: "8px"}}>
          <span id={this.state.labels.subQuestionLabel} title={this.props.qa.question.tooltip}>{this.props.qa.question.question}</span>
          {this.props.qa.question.note && <p>{this.props.qa.question.note}</p>}
        </label>
        <br role="presentation" />
        <TextField
          inputRef={this.errorRef}
          required
          aria-required="true"
          style={{ width: '288px' }}
          className='stringCapitalize'
          id={this.state.labels.inputId}
          label={label}
          variant="outlined"
          inputProps={{ 
            maxLength: !!inputLength && inputLength,
            "aria-describedby": this.state.labels.subQuestionLabel + errorId,
            "aria-invalid": true
           }}
          onChange={this.handleStringChange}
          onBlur={errorFound ? this.handleError : ""}
          value={this.state.stringInput}
          helperText={this.state.errorMsg}
          error={error}
          autoComplete="off"
        />
        <br role="presentation" />
        {error && (
          <span id={this.state.labels.errorId} style={{ color: '#D32029', marginLeft: '12px', fontSize: '12px', fontWeight: '700' }}>Field cannot be left blank.</span>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { updateData, getMessageToShow },
      dispatch
    )
  };
};

export default connect(null, mapDispatchToProps)(StringInput);

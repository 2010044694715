import React from 'react';

function capitalizeWords(str) {
    return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
}

function formatAddress(address) {
    if(!address) return '';
    
    let [city, stateZip] = address.split(", ");
    city = capitalizeWords(city);
    return `${city}, ${stateZip}`;
}
const AddressFormatter = ({ address }) => {
    const formattedAddress = formatAddress(address);
    return (<div>{formattedAddress}</div>);
};

export default AddressFormatter;
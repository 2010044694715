import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ScheduleForm from 'containers/schedule/scheduleForm';
import { getTransactionRef } from 'containers/common/innovel_util';
import { getTransactionDetails, getMessageToShow, getUTCTime, getTimeWindows, getOrderServiceMaintenanceAlert } from 'actions/index';
import LogoffLayer from './logoffLayer';
import 'styles/self_scheduler/_schedulerPage.scss';
import { result } from 'lodash';
import InnovelServiceAlert from 'containers/common/innovel_service_alert.js';
import { error } from 'jquery';
import parse from 'html-react-parser';

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.topScroll = React.createRef();
    const transID = getTransactionRef();
    this.state = {
      isManualSchedule: false,
      transactionID: transID,
      spinTheSpinner: false,
      showErrorMessage: false,
      scheduleTransactionDetail: " ",
      timeWindowMessage: '',
      buttonValidation: true,
      loading: true,
      unselectedTW: null
    };
  }

  acceptMethods = updateScheduleDate => {
    // Parent stores the method that the child passed
    this.updateScheduleDate = updateScheduleDate;
  };
  handleButton = flag => {
    this.setState({      
      buttonValidation: flag
    });
  }

  componentDidMount() {
    this.props.getOrderServiceMaintenanceAlert();
    document.title = "Schedule My Delivery or Return Pickup | Costco Logistics"
    this.topScroll.current.scrollIntoView();
    if (
      this.state.transactionID !== '' &&
      this.state.transactionID !== undefined &&
      this.state.transactionID !== null
    ) {
      this.setState({ startSpinner: true });
      this.props.getUTCTime().then(result => {
        const httpStatus = result.payload.status
        if(httpStatus === 200) {
          //var response = result.payload.data;
          sessionStorage.setItem("utcTime", result.payload.request.response)
        }
        else {
          sessionStorage.removeItem("utcTime");
        }
        this.props
        .getTransactionDetails(this.state.transactionID, 1)
        .then(result => {
          const httpStatus = result.error === undefined ? result.payload.status : result.payload.response !== undefined ? result.payload.response.status : result.payload.request.status;
          if (httpStatus === 200 ||  httpStatus === 400 || httpStatus === 500 ) {
            const status = result.payload.data.errorDetails === null ? result.payload.data.status : result.payload.data.status;
            if (status !== null || status !== undefined) {
              const validStatusCode = status.statusCode != null ? "True" : "False";
              if (status.statusCode === '00' && validStatusCode) {
                let transactionDetails = result.payload.data.serviceResponse;
                if (transactionDetails === null) {
                  localStorage.setItem(`homeError`, parse(result.payload.response.data.errorDetails.errors[0].message));
                  this.props.history.push('/');
                }
                else {
                  if (
                    transactionDetails !== undefined &&
                    transactionDetails.trackingOrder.length !== 0
                  ) {
                    // if(transactionDetails.scheduleDate !== null || transactionDetails.scheduleDate !== undefined || transactionDetails.scheduleDate !== "") {
                    //   const dceDates = [ transactionDetails.scheduleDate ];
                    //   this.props.getTimeWindows(dceDates);
                    // }
                    //set client name and phone for customer support message
                    this.setState({ clientCode: transactionDetails.trackingOrder[0].clientCode });
                    this.setState({ mscPhone: transactionDetails.trackingOrder[0].msc_phone_number });
                    this.setState({ timeWindowFlag: transactionDetails.timeWindowFlag, loading: false });

                    this.setState({
                      schedulerOrdType:
                        transactionDetails.scheduleTransactionDetail !== undefined ||
                          transactionDetails.scheduleTransactionDetail !== null
                          ? transactionDetails.scheduleTransactionDetail
                            .scheduleOrderType
                          : 0,
                      timeWindowMessage: result.payload.data.serviceResponse.timeWindowMessage ? parse(result.payload.data.serviceResponse.timeWindowMessage) : ""
                    });

                    if (
                      transactionDetails.scheduleTransactionDetail
                        .scheduleOrderType === 4
                    ) {
                      //Normal orders
                      this.setState({ stepNum: 1 });
                    } else {
                      if (
                        transactionDetails.trackingOrder[0].custService === undefined
                      ) {
                        let messageObj = {
                          alertType: 'error',
                          message: result.payload.data.errorDetails.errors[0].message ? parse(result.payload.data.errorDetails.errors[0].message) : parse(process.env.REACT_APP_MSC_CONTACT_US)
                        };
                        this.props.getMessageToShow(messageObj);
                      } else {
                        let messageObj = {
                          alertType: 'error',
                          message: parse(process.env.REACT_APP_MSC_CONTACT_US)
                        };
                        this.props.getMessageToShow(messageObj);
                      }
                    }
                  }
                }
                this.setState({
                  unselectedTW: transactionDetails.unselectedTW
                });
              }
              else {
                this.setState({ errorFlag: true });
                const errorMessage = result.error === undefined ? result.payload.data.errorDetails.errors[0].message :
                  result.payload.response.data.errorDetails.errors[0].message === undefined ?
                    'Please check the error' :
                    result.payload.response.data.errorDetails.errors[0].message;
                this.displayErrorMessage(errorMessage);
                //document.querySelector('.schedule-ele').hidden = true;
              }
            }
            else {
              const errorMessage = result.error === undefined ? result.payload.data.errorDetails.errors[0].message : result.payload.response.data.errorDetails.errors[0].message;
              this.displayErrorMessage(errorMessage);
            }
          }
          else {
            const errorMessage = result.error === undefined ? result.payload.data.errorDetails.errors[0].message :
              result.payload.response.data.errorDetails.errors[0].message === undefined ?
                'Please check the error' :
                result.payload.response.data.errorDetails.errors[0].message;
            this.displayErrorMessage(errorMessage);
          }
        }).catch(error => {
          this.displayErrorMessage(process.env.REACT_APP_MSC_CONTACT_US);
          document.querySelector('.schedule-ele').hidden = true;
        });
      }); 
    } else {
      this.props.history.push('/');
    }

  }
  displayErrorMessage = (errorMessage) => {
    var _message = parse(errorMessage)
    this.setState({ showErrorMessage: true, errorMessage: _message });
    this.setState({ loading: false });
  };

  next = e => {
    return this.updateScheduleDate().then(result => {
      const httpStatus = result.error === undefined ? result.payload.status : result.payload.response !== undefined ? result.payload.response.status : result.payload.request.status;
      if (httpStatus === 200 || httpStatus === 400 || httpStatus === 500) {
        const status = result.error === undefined ? result.payload.data.status : result.payload.response.data.status;
        if (status !== null || status !== undefined) {
          const validStatusCode = status.statusCode != null ? "True" : "False";
          if (status.statusCode === '00' && validStatusCode) {
            return result.payload.data.serviceResponse;
          }
          else {
            const errorMessage = result.error === undefined ?
              result.payload.response.data.errorDetails.errors[0].message :
              result.payload.response.data.errorDetails.errors[0].message === undefined ?
                'Please check the error' :
                result.payload.response.data.errorDetails.errors[0].message;
            this.displayErrorMessage(errorMessage);

          }

        }
      }else{
        this.displayErrorMessage(process.env.REACT_APP_MSC_CONTACT_US);
      }
    })
    .catch(error =>{
      const httpStatus = result === undefined 
      if(httpStatus === undefined)
      this.setState({
        errorState: true,
        errorMessage: parse(process.env.REACT_APP_MSC_CONTACT_US),
        showErrorMessage: true
    });
    })
};

  disableButton = flag => {
    this.setState({
      isManualSchedule: flag
    });
  };

  windowsFlag = flag => {
    this.setState({ windowsFlag: flag });
  }

  render() {
    const messObj = this.props.messageObj;
    const showDceFailureError = this.props.transactionDetails 
                                && this.props.transactionDetails?.dceErrorMessage
                                && (!this.props.transactionDetails.scheduleDate || new Date(this.props.transactionDetails.scheduleDate) < new Date());
                                // showDceFailureError-variable checks if dceErrorMessage is present and if sccheduleDate is null or in past.
    return (
      <div style={{ minHeight: '90vh' }} ref={this.topScroll}>
        <div className="container-fluid main-grid schedule-screen" role="main">
          <div className="row">
            <div id="mains" className="sr-only" tabIndex="-1"></div>
            {this.state.loading && (<div className="col-lg-10 offset-md-1 offset-lg-2">
              <p><br role="presentation" /></p>
            </div>)}
            {!this.state.loading && (<div className="col-lg-10 offset-md-1 offset-lg-2">
              <h1 className="t1" style={{ marginBottom: '8px' }}>Step 1 of 3 - Select a Date {this.state.timeWindowFlag && "and Time Window"}</h1>
              <InnovelServiceAlert messObj={messObj} name='SCHEDULER' />
              {!this.state.timeWindowFlag && <p className="body-copy">{this.state.timeWindowMessage}</p>}
              {/* {this.state.timeWindowFlag && !this.state.windowsFlag && (<br />)} */}
              {this.state.timeWindowFlag && (<p><span className="body-copy">Select a time window.  If the available times are not suitable, select a new date and time window that's best for you.</span></p>)}
            </div>)}
            <div className="col-md-5 col-lg-4 offset-md-1 offset-lg-2">
              <LogoffLayer />
              <div className="">
                <div className='' style={{ marginTop: '-10px', paddingBottom: '10px' }}>
                  {this.state.showErrorMessage && (
                    <div id="noSchedule" role="alert" tabIndex="-1" className="err-msg">
                      <p align="center" className="" style={{ padding: '5px 0', marginBottom: '0' }}>
                        {this.state.errorMessage}
                      </p>
                    </div>

                  )}
                </div>
              </div>
              {!this.state.errorFlag && (
                <>
                {showDceFailureError && (<p className='err-msg dceError' >{parse(this.props.transactionDetails?.dceErrorMessage)}</p>)}
                {!showDceFailureError && (<div className='schedule-ele'>
                  <ScheduleForm
                    disableButton={this.disableButton}
                    shareMethods={this.acceptMethods}
                    history={this.props.history}
                    buttonValidation={this.handleButton}
                    windowsFound={this.windowsFlag}
                    unselectedTW={this.state.unselectedTW}
                  />
                </div>
                )}
                </>
              )}

            </div>

          </div>

        </div>

      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getTransactionDetails, getMessageToShow, getUTCTime, getTimeWindows, getOrderServiceMaintenanceAlert }, dispatch)
  };
};

function mapStateToProps(state) {
  return {
    transactionDetails: state.transactionDetails,
    messageObj: state.maintenanceAlertMessage.maintenanceMessage
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);

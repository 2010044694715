import React, { Component } from 'react';
import 'styles/_font.scss';
import 'styles/footer.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen1: false,
      dropdownOpen2: false
    };
  }

  render() {
    return (
      <div
        id="footer"
        className="px-0 py-0 mx-0 my-0 mobile-hidden"
        role="contentinfo"
      >
        <div id="footerNav" className="sr-only" tabIndex="-1"> </div>
        <footer className="footer1">
          <div className="container-fluid">
            <div className="">
              <div className="footer-wrapper footer-wrapper-l">
                <p className="text-t4 p-top">
                  <a href="/userselfschedule" className='grey-900'>
                    <span>Schedule My Delivery<br className='tablet-only' /> or Return Pickup</span>
                  </a>
                </p>
                <p className="text-t4 p-top">
                  <span>
                    <a className='grey-900' href="/tracking">Track My Delivery</a>
                  </span>
                </p>
                <p className="text-t4 p-top">
                  <span>
                    <a href="https://customerservice.costco.com/app/answers/GuidedAssistant/a_id/1001241/loc/en_US/g_id/78" target='_blank' rel='noopener noreferrer' className='grey-900'>
                      Customer Service
                    </a>
                  </span>
                </p>
                <p className="text-t4 p-top">
                  <span>
                    <a href="https://www.costco.com/" rel="noopener noreferrer" target='_blank' className='grey-900'>
                      Costco.com
                    </a>
                  </span>
                </p>
              </div>
              <hr aria-hidden="true" className="bottom-hr" />
              <div className="footer-wrapper">
                <p className="text-t5 p-bottom">
                  <a href=" https://www.costco.com/terms-and-conditions-of-use.html" target='_blank' rel='noopener noreferrer' className='grey-800'>
                    Terms & Conditions
                  </a>
                </p>
                <p className="text-t5 p-bottom">
                  <a href="https://www.costco.com/privacy-policy.html" target='_blank' rel='noopener noreferrer' className='grey-800'>
                    Your Privacy Rights
                  </a>
                </p>
                <p className="text-t5 p-bottom">
                  <a href="https://www.costco.com/privacy-policy.html#cppa" target='_blank' rel='noopener noreferrer' className='grey-800'>
                   CA Notice
                  </a>
                </p>
                <p className="text-t5 p-bottom">
                <button id="ot-sdk-btn" class="ot-sdk-show-settings" style={{color:"#5f5f5f"}}>Cookie Settings</button>
                </p>
                <p className="text-t5 p-bottom">
                <a href="https://www.costco.com/DNSMIView" target='_blank' rel='noopener noreferrer'><img alt="California Consumer Privacy Act (CCPA) Opt-Out Icon" className='footer-img' src="https://mobilecontent.costco.com/live/resource/img/static-us-landing-pages/icon-privacy-choices.svg"/>
                </a>
                <a href="https://www.costco.com/DNSMIView " target='_blank' rel='noopener noreferrer' className='grey-800'>
                Your Privacy Choices
                </a>
                </p>
                {process.env.REACT_APP_DISPLAY_HEALTH_DATA == 'true' && 
                <p className="text-t5 p-bottom">
                  <a href="https://www.costco.com/consumer-health-data-privacy-policy.html" target='_blank' rel='noopener noreferrer' className='grey-800'>
                  Consumer Health Data
                  </a>
                </p>
                }
              </div>
              <div className="py-4 d-flex align-items-center">
                <div className="col-md-12 col-lg-12">
                  <p className="text-center text-bottom">
                    © 2020 - {new Date().getFullYear()} Costco Wholesale Corporation. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
export default Footer;


import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { clearSessionStorage } from 'containers/common/innovel_util';
import { withRouter } from 'react-router-dom';
import { TimeWindowModal } from 'containers/common/timeWindowModal.js';
import moment from 'moment';

class TimeWindowTimer extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
          //time set to 3 mins
          timeout: 180000,
          timer: null,
          showModal: false,
          startTime: localStorage.getItem("timerStartTime") ? localStorage.getItem("timerStartTime") : moment.utc(Date.now()),
          useModal: true,
          modalClosed: false
        };
      }
    
    startTimer = (time) => {
      clearInterval(this.timer);
      this.setState({ timerTime: time });
      var timerLimit = time;
      this.timer = setInterval(() => {              
          var timerCount = JSON.parse(moment(Date.now()).diff(this.state.startTime, 'seconds')) * 1000;
          const newTime = timerLimit - timerCount;

          if (newTime >= 0) {
            this.setState({
                timerTime: newTime
            });
            if(newTime <= (timerLimit / 2)) {
              if(this.state.useModal && !this.state.modalClosed) {
                this.setState({ showModal: true });
              }
            }
            localStorage.setItem("timerCount", newTime);
          } else {
          this.handleLogout();
          }
      }, 1000);    
    
    };

    handleClose = () => {
      this.setState({ showModal: false, modalClosed: true });
    };

    handleLogout = () => {
      this.setState({ showModal: false });
        clearInterval(this.state.timer);
        clearInterval(this.timer);
        clearSessionStorage();
        this.props.history.push('/userselfschedule?timeoutWindow=True');
    };

    componentDidMount() {
        this.setState({ useModal:  JSON.parse(localStorage.getItem("timerCount")) > (this.state.timeout / 2) ? true : false }); 
        this.startTimer(this.state.timeout);
    }

    render() {
        const { timerTime } = this.state;
        let seconds = ('0' + Math.floor((timerTime / 1000) % 60) % 60).slice(-2);
        let minutes = ('0' + Math.floor((timerTime / 60000) % 60)).slice(-2);
        return (
          <div>
            <div className="">
              <TimeWindowModal
                showModal={this.state.showModal}
                handleClose={this.handleClose}
                minutes={minutes}
                seconds={seconds}
                timeOutType={"Scheduling"}
              />
            </div>
          </div>
        );
      }

    componentWillUnmount() {
        clearInterval(this.state.timer);
        clearInterval(this.timer);
    }

}

export default withRouter(TimeWindowTimer);
import React from 'react';
import 'styles/_font.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import {
  Nav,
  NavItem
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from 'images/costcoLogistics_s.png';
import 'styles/_header.scss';
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      showBurgerMenu: false,
      dropdownOpen1: false,
      dropdownOpen2: false,
      dropdownOpen3: false,
      dropdownOpen4: false,
      dropdownOpen5: false,
      dropdownOpen6: false,
      dropdownOpen7: false,
      hideHeader: false
    };
  }

  toggle(id) {
    this.setState({ [id]: !this.state[id] });
  }

  onMouseEnter(e, i) {
    this.setState({ dropdownOpen1: false });
    this.setState({ dropdownOpen2: false });
    this.setState({ dropdownOpen3: false });
    this.setState({ dropdownOpen4: false });
    this.setState({ dropdownOpen5: false });
    this.setState({ dropdownOpen6: false });
    this.setState({ dropdownOpen7: false });

    if (e === 1) {
      this.setState({ dropdownOpen1: true });
    } else if (e === 2) {
      this.setState({ dropdownOpen2: true });
    } else if (e === 3) {
      this.setState({ dropdownOpen3: true });
    } else if (e === 4) {
      this.setState({ dropdownOpen4: true });
    } else if (e === 5) {
      this.setState({ dropdownOpen5: true });
    } else if (e === 6) {
      this.setState({ dropdownOpen6: true });
    } else if (e === 7) {
      this.setState({ dropdownOpen7: true });
    }
  }

  onMouseLeave(e, i) {
    this.setState({ dropdownOpen1: false });
    this.setState({ dropdownOpen2: false });
    this.setState({ dropdownOpen3: false });
    this.setState({ dropdownOpen4: false });
    this.setState({ dropdownOpen5: false });
    this.setState({ dropdownOpen6: false });
    this.setState({ dropdownOpen7: false });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.transactionDetails !== undefined &&
      nextProps.transactionDetails.length !== 0 &&
      (nextProps.transactionDetails.isDuplicate === false &&
        nextProps.transactionDetails.allowToSchedule)
    ) {
      this.setState({ hideHeader: true });
    } else {
      this.setState({ hideHeader: false });
    }
    if (
      nextProps.selfScheduleResponse !== undefined &&
      nextProps.selfScheduleResponse !== null &&
      nextProps.selfScheduleResponse.length !== 0
    ) {
      if (nextProps.selfScheduleResponse.failureType !== 1) {
        this.setState({ hideHeader: false });
      }
    }
    if (
      nextProps.scheduleIncorrectInfoResponse !== undefined &&
      nextProps.scheduleIncorrectInfoResponse !== null &&
      nextProps.scheduleIncorrectInfoResponse.length !== 0
    ) {
      if (nextProps.scheduleIncorrectInfoResponse.failureType !== 1) {
        this.setState({ hideHeader: false });
      }
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.closeBurgerMenu);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closeBurgerMenu)
  }

  closeBurgerMenu = (e) => {
    document.body.style.backgroundColor = "white";
    if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
      this.setState({ showBurgerMenu: false });
    }
  }

  closeHamBurgerMenu = () => {
    this.setState({ showBurgerMenu: false });
  }

  //to hide the mobile menu when shift tab pressed
  toggleBurgerMenus = (e) => {
    if (e.key === 'Tab' && e.shiftKey) {
      this.setState({ showBurgerMenu: !this.state.showBurgerMenu });
    }
  }

  toggleBurgerMenu = () => {
    let menuTop = document.querySelector('.navbar-header').offsetTop + 50;
    document.querySelector('#mobileMenu').style.marginTop = (menuTop + 'px');
    this.setState({ showBurgerMenu: !this.state.showBurgerMenu })
  }

  togglesClose = (e) => {

    if (e.key === 'Escape') {
      this.setState({ showBurgerMenu: !this.state.showBurgerMenu })
    }
  }

  render() {
    if (this.state.hideHeader) {
      return <div />;
    }

    return (
      <div className="nav-border header-parent" role="banner">
        <div className="container-fluid">
          <div
            className="navbar-header row"
            ref={this.wrapperRef}
          >
            <div className="col-3">
              <a href="#mains" className="skip-main-c">Skip to Main Content</a>

              <div className="row">
                <a className="navbar-brand logo-fix" href="/">
                  <img
                    src={logo}
                    alt="Costco Wholesale Logistics"
                    className="img-fluid logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-9" role="navigation">
              <div className="">
                <div className="navbar-expand-sm navbar-light pull-right hide-sm">
                  <button
                    className="navbar-toggler custom-toggler"
                    type="button"
                    id="Menu"
                    role="Menu"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Menu"
                    style={{
                      borderColor: 'transparent',
                    }}
                    aria-haspopup="true"
                    onKeyUp={this.togglesClose}
                    onClick={this.toggleBurgerMenu}
                  >
                    <span className="navbar-toggler-icon"></span>
                    <span className="text-t7" style={{ display: 'block' }}>Menu</span>
                  </button>
                </div>
                <ul className="nav navbar-lg-view navbar-nav pull-right"
                  aria-labelledby="Menu"
                >
                  <NavItem className="separator">
                    <a href="/userselfschedule" className="nav-font">
                      Schedule My Delivery or Return Pickup
                    </a>
                  </NavItem>
                  <NavItem className="separator">
                    <a href="/tracking" className="nav-font">
                      Track My Delivery
                    </a>
                  </NavItem>
                  <NavItem className="separator">
                    <a href="https://customerservice.costco.com/app/answers/GuidedAssistant/a_id/1001241/loc/en_US/g_id/78" className="nav-font" target='_blank' rel='noopener noreferrer' onClick={this.toggleBurgerMenu}>
                      Customer Service
                    </a>
                  </NavItem>
                  <NavItem>
                    <a href="https://www.costco.com/" className="nav-font" target='_blank' rel='noopener noreferrer' onClick={this.toggleBurgerMenu}>
                      Costco.com
                    </a>
                  </NavItem>
                </ul>
                <div
                  onMouseLeave={this.onMouseLeave.bind(this, 1)}
                  className={`${this.state.showBurgerMenu ? 'overlay' : ''}`}
                  onClick={(e) => this.closeHamBurgerMenu(e)}
                >
                  <Nav
                    id="mobileMenu"
                    onMouseLeave={this.onMouseLeave.bind(this, 1)}
                    className={`navbar navbar-toggleable-md navbar-fixed-top justify-content-end animate navbar-custom ${this.state.showBurgerMenu ? 'show' : ''}`}
                    style={{
                      float: 'right',
                      position: 'relative',
                      marginTop: 50,
                      border: "1px solid #9c9c9c",
                      padding: 0,
                      boxShadow: "0px 5px 10px #333333",
                      blur: 10,
                    }}
                  >
                    <div className="nav navbar-nav pull-right hide-sm" style={{
                      width: "100%",
                      padding: "0px 20px 0px 20px"
                    }}>
                      <NavItem className="menu-nav-item">
                        <a href="/userselfschedule" className="menu-nav-font" onClick={this.toggleBurgerMenu} onKeyDown={this.toggleBurgerMenus}>
                          Schedule My Delivery or Return Pickup
                        </a>
                      </NavItem>
                      <NavItem className="menu-nav-item">
                        <a href="/tracking" className="menu-nav-font" onClick={this.toggleBurgerMenu}>
                          Track My Delivery
                        </a>
                      </NavItem>
                      <NavItem className="menu-nav-item">
                        <a href="https://customerservice.costco.com/app/answers/GuidedAssistant/a_id/1001241/loc/en_US/g_id/78" className="menu-nav-font" target='_blank' rel='noopener noreferrer' onClick={this.toggleBurgerMenu}>
                          Customer Service
                        </a>
                      </NavItem>
                      <NavItem className="menu-nav-item">
                        <a href="https://www.costco.com/" className="menu-nav-font" target='_blank' rel='noopener noreferrer' onClick={this.toggleBurgerMenu}>
                          Costco.com
                        </a>
                      </NavItem>
                      <NavItem className="menu-nav-item">
                        <a class="skip-to-content-link menu-nav-font" rel='noopener noreferrer' onFocus={this.toggleBurgerMenu} href="#main">
                          Skip to content
                        </a>
                      </NavItem>
                    </div>
                  </Nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactionDetails: state.transactionDetails,
    selfScheduleResponse: state.selfScheduleResponse,
    scheduleIncorrectInfoResponse: state.scheduleIncorrectInfoResponse
  };
}

export default connect(mapStateToProps, null)(Header);


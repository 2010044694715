import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import 'styles/_deliveryform.scss';
import 'styles/_tracking.scss';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'styles/_font.scss';
import {
  updateData,
  getMessageToShow
} from 'actions/index';
import { phoneNoFormat, plainPhoneNo, phoneNoFormatInput } from 'containers/common/innovel_util';
import { SEMANTIC_TYPES } from 'constants.js';
import { QuestionLabels } from 'containers/common/innovel_util';

export class PhoneNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneInput: !props?.parentAnswer?.currentValue && !props?.parentAnswer?.newValue
        ? phoneNoFormat(props.qa.answer.currentValue)
        : '',
      phoneError: '',
      labels: QuestionLabels(SEMANTIC_TYPES, "PHONE_NUMBER")
    };
    this.errorRef = React.createRef();
  }

  componentDidMount() {
    const { parentAnswer = {} } = this.props;
    if (!parentAnswer?.currentValue && !parentAnswer?.newValue) {
      this.props.updateData(
        plainPhoneNo(this.state.phoneInput),
        this.props.qa.question.id,
        this.props.qa.question.semanticType
      );
    }
  }

  handlePhoneNumberChange = e => {
    const value = e.target.value;
    const formattedPhoneNo = phoneNoFormatInput(value);
    this.setState({ phoneInput: formattedPhoneNo });
    this.props.updateData(
      plainPhoneNo(e.target.value),
      this.props.qa.question.id,
      this.props.qa.question.semanticType
    );
    var phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    if (formattedPhoneNo && formattedPhoneNo.match(phoneno)) {
      this.setState({
        phoneError: ''
      });
      this.props.clearErrorId(this.props.qa.question.id);
    } else {
      this.setState({
        phoneError: 'Enter a valid phone number.'
      });
    }
  };

  render() {
    const { errorIDs = [] } = this.props;
    const error = _.find(errorIDs, ['id', this.props.qa.question.id]);
    var errorFound = false;
    if(error) {
      var errorFound = true;
      if(error.rank == errorIDs[0].rank && this.errorRef.current !== null) {
        this.errorRef.current.focus();
      }
    }
    const errorId = error ? " " + this.state.labels.errorId : "";
    return (
      <div style={{ marginBottom: "18px", marginTop: "15px" }}>
        <label className="text-xxsmall">
          <span id={this.state.labels.subQuestionLabel} title={this.props.qa.question.tooltip}>{this.props.qa.question.question}</span>
          {this.props.qa.question.note && <p>{this.props.qa.question.note}</p>}
        </label>
        <br role="presentation" />
        <TextField          
          inputRef={this.errorRef}
          required
          aria-required="true"
          style={{ width: '288px' }}
          id={this.state.labels.inputId}
          label="Phone Number"
          value={this.state.phoneInput}
          variant="outlined"
          onChange={this.handlePhoneNumberChange}
          error={error}
          autoComplete="off"
          inputProps={{
            "aria-describedby": this.state.labels.subQuestionLabel + errorId,
            "aria-invalid": true
          }}
        />
        <br role="presentation" />
        {error && (
          <span id={this.state.labels.errorId} style={{ color: '#D32029', marginLeft: '12px', fontSize: '12px', fontWeight: '700' }}>Enter a valid phone number.</span>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { updateData, getMessageToShow },
      dispatch
    )
  };
};

export default connect(null, mapDispatchToProps)(PhoneNumber);

import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import 'styles/_deliveryform.scss';
import CheckMark from 'images/Checkmark.png';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'styles/_font.scss';
import { updateData, getMessageToShow } from 'actions/index';
import QuestionAnswerWrapper from 'containers/deliverydetails/questionAnswerWrapper';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import parse from 'html-react-parser';
import { SEMANTIC_TYPES } from 'constants.js';
import { QuestionLabels } from 'containers/common/innovel_util';

export class YesNo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yesNo: props.answer.newValue,
      notSelected: props.qa.answer.newvalue === true || false ? true : false,
      labels: QuestionLabels(SEMANTIC_TYPES, this.props.qa.question.semanticType) 
    };
  }

  componentDidMount() {
    (this.state.yesNo !== this.props.answer.currentValue || this.state.yesNo === null)
      && this.handleYesNoChange({ target: { value: this.props.answer.currentValue } });
  }

  handleYesNoChange = e => {
    const userAnswer = e.target.value === 'true' ? true : false;
    this.props.clearErrorId(this.props.qa.question.id);
    this.props.clearErrorId(this.props.qa.question?.subQuestionAnswers?.[0]?.question?.id);
    this.props.clearErrorId(this.props.qa.question?.subQuestionAnswers?.[0]?.question?.subQuestionAnswers?.[0]?.question?.id)
    this.state.yesNo &&
      Array.isArray(this.props.qa.question.subQuestionAnswers) &&
      this.props.qa.question.subQuestionAnswers.forEach(subQa => {
        this.props.clearErrorId(subQa.question.id);
      })
    // clear subqa answers on change
    Array.isArray(this.props.qa.question.subQuestionAnswers) &&
      this.props.qa.question.subQuestionAnswers.forEach(subQa => {
        let ans = null;
        if (subQa.question.answerType === 'CHECK_BOX') {
          ans = subQa.answer.map(ans => {
            return { ...ans, newValue: false };
          })
        } else if (subQa.question.answerType === 'DROP_DOWN_STRING' || subQa.question.answerType === 'TEXT_BOX' || subQa.question.answerType === 'PHONE_NUMBER') {
          if (subQa.answer.currentValue && userAnswer) {
            ans = subQa.answer.currentValue;
          }
          if (subQa.question?.subQuestionAnswers?.[0].answer) {
            const newVal = userAnswer ? subQa.question.subQuestionAnswers[0].answer.currentValue : null;
            this.props.updateData(newVal, subQa.question.subQuestionAnswers[0].question.id, subQa.question.subQuestionAnswers[0].question.semanticType);
          }
        }
        this.props.updateData(ans, subQa.question.id, subQa.question.semanticType);
      })
    this.setState({ yesNo: userAnswer }, () => {
      this.props.updateData(userAnswer, this.props.qa.question.id, this.props.qa.question.semanticType);
    });
  };

  render() {
    const { errorIDs = [] } = this.props;
    const error = _.find(errorIDs, ['id', this.props.qa.question.id]);
    return (
      <div>
        {/* <span id={this.state.labels.label} style={{ marginTop: '10px', fontStyle: "normal",fontSize: "14px", lineHeight: "20px" }} title={this.props.qa.question.tooltip}>{this.props.qa.question.question}</span>
        {this.props.qa.question.note && <p>{this.props.qa.question.note}</p>}
        <p /> */}
        <Col >
          <Row sm="2">
            <fieldset>
              <legend>
                <span id={this.state.labels.label} style={{ marginTop: '10px', fontStyle: "normal",fontSize: "14px", lineHeight: "20px" }} title={this.props.qa.question.tooltip}>{this.props.qa.question.question}</span>
                {this.props.qa.question.note && <p style={{fontStyle: "normal", fontSize: "14px"}}>{this.props.qa.question.note}</p>}
              </legend>
              <RadioGroup
                aria-labelledby={this.state.labels.label}
              >
                <FormControlLabel
                  value={true}
                  style={{ marginBottom: "0" }}
                  control={<Radio size="small" />}
                  label="Yes"
                  checked={this.state.yesNo}
                  onChange={this.handleYesNoChange}
                />
                <FormControlLabel
                  value={false}
                  style={{ marginBottom: "0" }}
                  control={<Radio size="small" />}
                  label="No"
                  checked={!this.state.yesNo}
                  onChange={this.handleYesNoChange}
                />
              </RadioGroup>
            </fieldset>
          </Row>
        </Col>
        {this.state.yesNo &&
          this.props.qa.question.showNestedQtnOn === true &&
          this.props.qa.question.subQuestionAnswers &&
          this.props.qa.question.subQuestionAnswers.length > 0 &&
          this.props.qa.question.subQuestionAnswers.map((subQa, index) => {
            return <QuestionAnswerWrapper parentAnswer={this.props.answer} key={index} qa={subQa} errorIDs={this.props.errorIDs} clearErrorId={this.props.clearErrorId} />;
          })}
        {!this.state.yesNo &&
          this.props.qa.question.showNestedQtnOn !== true &&
          this.props.qa.question.subQuestionAnswers &&
          this.props.qa.question.subQuestionAnswers.length > 0 &&
          this.props.qa.question.subQuestionAnswers.map((subQa, index) => {
            return <QuestionAnswerWrapper parentAnswer={this.props.answer} key={index} qa={subQa} errorIDs={this.props.errorIDs} clearErrorId={this.props.clearErrorId} />;
          })}
        {!this.state.yesNo &&
          this.props.qa.question.showNestedStmtOn === true &&
          this.props.qa.question.statements &&
          this.props.qa.question.statements.length > 0 &&
          this.props.qa.question.statements.map((statement, index) => {
            return (
              <div key={index}>
                <img src={CheckMark} alt='check mark' style={{ width: '13.33px', height: '10.13px', marginRight: '9.33px', marginBottom: '5px' }}></img>{parse(statement.statement)}
                <br role="presentation" />
              </div>
            );
          })}
        {error && (
          <span id='invalidOption' style={{ color: '#D32029', marginLeft: '12px', fontSize: '12px', fontWeight: '700' }}>Select either yes or no.</span>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ updateData, getMessageToShow }, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(YesNo);

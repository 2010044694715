export const MOBILE_WIDTH = 575;
export const SEMANTIC_TYPES = [
    {
        semanticType: "PHONE_NUMBER",
        id:"phone-num-id",
        label: "phone-num-label",
        inputId: "phone-num-input",
        subQuestionLabel: "new-phone-num-label",
        errorId: "err-help-phone",
        errorMessage: "Enter a valid phone number"
    },
    {
        semanticType: "GATED",
        id: "gate-id",
        label: "gated-label",
        inputId: "gate-code-input",
        subQuestionLabel: "entrance-label",
        errorId: "err-help-gated",
        errorMessage: ""
    },
    {
        semanticType: "GATE_PASS_CODE",
        id: "gate-pass-code-id",
        label: "gated-pass-code-label",
        inputId: "gate-code-input",
        subQuestionLabel: "gated-pass-code-label",
        errorId: "err-help-gate-code",
        errorMessage: "Enter a valid gate code"
    },
    {
        semanticType: "NEWLY_BUILT",
        id: "newly-built-id",
        label: "newly-built-label",
        inputId: "street-name-input",
        subQuestionLabel: "street-name-label",
        errorId: "err-help-street",
        errorMessage: "Enter a valid street address"
    },
    {
        semanticType: "TRUCK",
        id: "delivery-truck-id",
        label: "delivery-truck-label",
        inputId: "delivery-truck-input",
        subQuestionLabel: "delivery-truck-label",
        errorId: "",
        errorMessage: ""
        
    },
    {
        semanticType: "REPLACEMENT",
        id: "replacement-id",
        label: "replacement-label",
        inputId: "replacement-input",
        subQuestionLabel: "replacement-label",
        errorId: "",
        errorMessage: ""
    },
    {
        semanticType: "REPLACEMENT_ITEM",
        id: "replacement-id",
        label: "replacement-label",
        inputId: "replacement-input",
        subQuestionLabel: "replacement-label",
        errorId: "",
        errorMessage: ""
    },
    {
        semanticType: "HAUL_AWAY",
        id: "haul-away-id",
        label: "haul-away-label",
        inputId: "haul-away-input",
        subQuestionLabel: "haul-away-label",
        errorId: "",
        errorMessage: ""
    },
    {
        semanticType: "HAUL_AWAY_ITEM",
        id: "haul-away-id",
        label: "haul-away-label",
        inputId: "haul-away-input",
        subQuestionLabel: "haul-away-label",
        errorId: "",
        errorMessage: ""
    },
    {
        semanticType: "HAUL_AWAY_BULK",
        id: "haul-away-id",
        label: "haul-away-label",
        inputId: "haul-away-input",
        subQuestionLabel: "haul-away-label",
        errorId: "",
        errorMessage: ""
    },
    {
        semanticType: "REVERSE_DOOR",
        id: "reverse-door-id",
        label: "reverse-door-label",
        inputId: "reveres-door-input",
        subQuestionLabel: "reverse-door-label",
        errorId: "",
        errorMessage: ""
    },
    {
        semanticType: "SHUT_OFF_VALVE",
        id: "shut-off-valve-id",
        label: "shut-off-valve-label",
        inputId: "shut-off-valve-input",
        subQuestionLabel: "shut-off-valve-label",
        errorId: "",
        errorMessage: ""
    }
];
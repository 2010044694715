import React, { Component } from 'react';
import 'styles/_deliveryform.scss';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'styles/_font.scss';
import YesNo from './answerTypes/yesNo';
import StringDropDown from './answerTypes/stringDropDown';
import StringInput from './answerTypes/stringInput';
import PhoneNumber from './answerTypes/phoneNumber';
import CheckBox from './answerTypes/checkBox';
import HaulAway from './answerTypes/haulAway';
import NumberDropDown from './answerTypes/numberDropDown';

export class QuestionAnswerWrapper extends Component {

  switchComponents = qa => {
    switch (qa.question.answerType) {
      case 'RADIO_BUTTON':
        if (qa.question.semanticType === 'HAUL_AWAY') {
          return <HaulAway
            qa={this.props.qa}
            subQAanswer={this.props.qa.question.subQuestionAnswers && this.props.qa.question.subQuestionAnswers[0] && this.props.qa.question.subQuestionAnswers[0].answer}
            errorIDs={this.props.errorIDs}
            errorTrackingNumbers={this.props.errorTrackingNumbers}
            clearErrorId={this.props.clearErrorId}
          />;
        } else {
          return <YesNo
            qa={qa}
            answer={qa.answer}
            errorIDs={this.props.errorIDs}
            clearErrorId={this.props.clearErrorId}
          />;
        }
      case 'DROP_DOWN_STRING':
        return <StringDropDown qa={this.props.qa} errorIDs={this.props.errorIDs} clearErrorId={this.props.clearErrorId} />;
      case 'DROP_DOWN_NUMBER':
        return <NumberDropDown qa={this.props.qa} errorIDs={this.props.errorIDs} errorTrackingNumbers={this.props.errorTrackingNumbers} clearErrorId={this.props.clearErrorId} />;
      case 'TEXT_BOX':
        return <StringInput qa={this.props.qa} errorIDs={this.props.errorIDs} clearErrorId={this.props.clearErrorId} />;
      case 'PHONE_NUMBER':
        return <PhoneNumber parentAnswer={this.props.parentAnswer} qa={this.props.qa} errorIDs={this.props.errorIDs} clearErrorId={this.props.clearErrorId} />;
      case 'CHECK_BOX':
        return <CheckBox qa={this.props.qa} errorIDs={this.props.errorIDs} clearErrorId={this.props.clearErrorId} />;
    }
  };

  render() {
    return <div>{this.switchComponents(this.props.qa)}</div>;
  }

  componentWillUnmount() { }
}

export default QuestionAnswerWrapper;
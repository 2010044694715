import axios from 'axios';
import { API_ROOT } from 'config/api-config';
import { API_SERVER } from 'config/api-config';
import { TOKEN_ROOT } from 'config/api-config';
import { APP_CONTEXT } from 'config/api-config';
import { ALERT_ROOT } from 'config/api-config';
import CryptoJS from 'crypto-js';
import moment from 'moment';

export const encryptedString = () => {
  var date = "";
  if(sessionStorage.getItem("utcTime") != null || sessionStorage.getItem("utcTime") != undefined) {
    date = moment(sessionStorage.getItem("utcTime")).format('x');
  }
  else {
    //'2022-09-14T12:53:44-04:00'
    date = moment().utc().format('x');
  }
  
  const key = date + " ";
  const secret = process.env.REACT_APP_SECRET;
  const encrypted = CryptoJS.AES.encrypt(key, secret);
  return encrypted.toString();
}

export const encryptedStringScheduler = () => {
  var date = "";
  if(sessionStorage.getItem("utcTime") != null || sessionStorage.getItem("utcTime") != undefined) {
    date = moment(sessionStorage.getItem("utcTime")).format('x');
  }
  else {
    //'2022-09-14T12:53:44-04:00'
    date = moment().utc().format('x');
  }
  
  const key = date + " ";
  const secret = process.env.REACT_APP_SECRET;
  const encrypted = CryptoJS.AES.encrypt(key, secret);
  return encrypted.toString();
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

const clientId = process.env.REACT_APP_CLIENT_ID;

const current = moment.utc().format();
const date = moment.utc().format('x');

const PREFIX_LOGIN = 'Bearer ';

const httpClient = axios.create();

httpClient.defaults.timeout = 60000;

httpClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
    //   if (error.response.status === 401) {
    //     // uses windows sessionStorage
    //     localStorage.removeItem('token');
    //     window.location.href = APP_CONTEXT + '/login';
    //   } else if (error.response.status === 403) {
    //     localStorage.removeItem('token');
    //     window.location.href = APP_CONTEXT + '/unauth';
    //   }
    }
    return Promise.reject(error);
  }
);

class ApiRepo {
  serviceCall = (httpMethod, path, inputParams, header) => {
    let url = '';

    if (path === '/oauth/token' || path === '/oauth/revoke-token') {
      url = TOKEN_ROOT + path;
      // url = "http://localhost:8085/innovel-services/rft/api/oauth/token";
      //           url = "http://localhost:8085/innovel-services/rft/api/oauth/token";
    } else {
      url = API_ROOT + path;
    }

    // setting access token
    let headerToSet;
    if (header === undefined) {
      if (localStorage.getItem('token') !== null) {
        headerToSet = {
          headers: {
            Authorization: PREFIX_LOGIN + localStorage.getItem('token'),
            'innovel-beta': 'true',
            'innovel-question-beta': 'true',
            'client-Id' : clientId,
            'ext-cl-cus': encryptedStringScheduler(),
            'trace-id': uuidv4()
          }
        };
      } else {
        headerToSet = {
          headers: {
            'innovel-beta': 'true',
            'innovel-question-beta': 'true',
            'client-Id': clientId,
            'ext-cl-cus': encryptedStringScheduler(),
            'trace-id': uuidv4()
          }
        };
      }
    } else {
      if (localStorage.getItem('token') !== null) {
        headerToSet = {
          headers: {
            ...header,
            'innovel-beta': 'true',
            'innovel-question-beta': 'true',
            Authorization: PREFIX_LOGIN + localStorage.getItem('token'),
            'client-Id' : clientId

          }
        };
      } else {
        headerToSet = {
          headers: {
            ...header,
            'innovel-beta': 'true',
            'innovel-question-beta': 'true',
            'client-Id' : clientId

          }
        };
      }
    }

    switch (httpMethod) {
      case 'get':
        return httpClient.get(url, headerToSet);
      case 'post':
        return httpClient.post(url, inputParams, headerToSet);
      case 'put':
        return httpClient.put(url, inputParams, headerToSet);
      case 'delete':
        return httpClient.delete(url, headerToSet);
      default:
        throw new Error('Unsupported http method');
    }
  };

  maintenanceServiceCall = (httpMethod, path, inputParams, header) => {
    let url = ALERT_ROOT + path;

    switch (httpMethod) {
      case 'get':
        return httpClient.get(url);
      default:
        throw new Error('Unsupported http method');
    }
  };

  //Testing
  utcTimeServiceCall = (httpMethod, path) => {
    let url = ALERT_ROOT + path;

    switch (httpMethod) {
      case 'get':
        return httpClient.get(url);
      default:
        throw new Error('Unsupported http method');
    }
  }
  //End Testing

  serviceCallV2 = (httpMethod, path, context, inputParams, header) => {
    var contextPath = '';
    switch (context) {
      case 'track':
        contextPath = '';
        break;
      default:
        contextPath = '';
        break;
    }

    let url = '';
    url = API_SERVER + contextPath + path;

    let headerToSet;
    if (header === undefined) {
      if (localStorage.getItem('token') !== null) {
        headerToSet = {
          headers: {
            'innovel-beta': 'true',
            'innovel-question-beta': 'true',
            Authorization: PREFIX_LOGIN + localStorage.getItem('token'),
            'ext-cl-cus': encryptedString()
          }
        };
      } else {
        headerToSet = {
          headers: {
            'innovel-beta': 'true',
            'innovel-question-beta': 'true',
            'ext-cl-cus': encryptedString()
          }
        };
      }
    } else {
      if (localStorage.getItem('token') !== null) {
        headerToSet = {
          headers: {
            ...header,
            'innovel-beta': 'true',
            'innovel-question-beta': 'true',
            Authorization: PREFIX_LOGIN + localStorage.getItem('token')
          }
        };
      } else {
        headerToSet = {
          headers: {
            ...header,
            'innovel-beta': 'true',
            'innovel-question-beta': 'true'
          }
        };
      }
    }

    switch (httpMethod) {
      case 'get':
        return httpClient.get(url, headerToSet);
      case 'post':
        return httpClient.post(url, inputParams, headerToSet);
      case 'put':
        return httpClient.put(url, inputParams, headerToSet);
      case 'delete':
        return httpClient.delete(url, headerToSet);
      default:
        throw new Error('Unsupported http method');
    }
  };
}

export let apiRepo = new ApiRepo();

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import 'styles/_deliveryform.scss';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'styles/_font.scss';
import {
  updateData,
  getMessageToShow
} from 'actions/index';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';

const iconStyles = {
  selectIcon: {
    color: "#3071A9"
  }
};
const CustomExpandMore = withStyles(iconStyles)(
  ({ className, classes, ...rest }) => {
    return (
      <ExpandMoreIcon
        {...rest}
        fontSize='large'
        className={clsx(className, classes.selectIcon)}
      />
    );
  }
);

export class NumberDropDown extends Component {
  constructor(props) {
    super(props);
    const ansArray = Array.isArray(props.qa.answer) && props.trackingNumber
        ? props.qa.answer.filter(ans => ans.answer.trackingNumber === props.trackingNumber)
        : [props.qa.answer]
    this.state = {
      value: ansArray && ansArray.length && ansArray[0] && ansArray[0].newValue && ansArray[0].newValue,
      answer: ansArray && ansArray.length && ansArray[0],
      selectOpen: false
    };
    this.errorRef = React.createRef();
  }

  componentDidMount() {
    this.selectChoice({ target: { value: this.state.value } });
  }

  selectChoice = (e) => {
    const { trackingNumber, errorTrackingNumbers } = this.props;
    this.props.clearErrorId(this.props.qa.question.id, errorTrackingNumbers.filter(t => t !== trackingNumber))
    const value = parseInt(e.target.value)
    let answer;
    if (!Array.isArray(this.props.qa.answer)) {
      answer = this.props.qa.answer;
      answer.newValue = value;
    } else {
      answer = this.props.qa.answer.map(ans => {
        if (ans.answer.trackingNumber === trackingNumber) {
          ans.newValue = value
        }
        return ans;
      })
    }
    this.props.updateData(
      answer,
      this.props.qa.question.id,
      this.props.qa.question.semanticType
    );
    this.setState({ value });
  };

  render() {
    let numberArray = [];
    for (let i = 1; i <= this.state.answer.quantity; i++) {
      numberArray.push(i);
    }
    const { errorIDs = [], qa } = this.props;
    const error = _.find(errorIDs, ['id', qa.question.id]) && (
      (this.state.answer.newValue === null) ||
      (this.state.answer.newValue === 0) ||
      isNaN(this.state.answer.newValue)
    )
    if(error && qa.question.id == errorIDs[0].id && this.errorRef.current !== null) {
      this.errorRef.current.focus();
    }
    return (
      <div id={this.props.trackingNumber} className='container-fluid'>
        {
          <div >
            <label className="text-xxsmall">
              {' '}
              <span title={qa.question.tooltip}>{qa.question.question}</span>
              {qa.question.note && qa.question.note !== 'NULL' && (
                <p>{qa.question.note}</p>
              )}
            </label>
            <br role="presentation" />
            <FormControl fullWidth error={error}>
              <InputLabel id="select-label">Number of Items</InputLabel>
              <Select
                labelId="select-label"
                id="number-of-items"
                value={this.state.value}
                label="Number of Items"
                onChange={this.selectChoice}
                IconComponent={CustomExpandMore}
                inputProps={{
                  inputRef: this.errorRef
                }}
              >
                {numberArray.map((number, index) => {
                  return (<MenuItem key={index} value={number}>{number}</MenuItem>)
                })}
              </Select>
            </FormControl>
            {error && (
              <div style={{ color: '#D32029', marginLeft: '12px', fontSize: '12px', fontWeight: '700' }}>
                Select number of items to haul away.
              </div>
            )}
          </div>
        }
      </div>
    );
  }
}

NumberDropDown.defaultProps = {
  errorIDs: []
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { updateData, getMessageToShow },
      dispatch
    )
  };
};

export default connect(null, mapDispatchToProps)(NumberDropDown);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import 'styles/_deliveryform.scss';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'styles/_font.scss';
import { updateData, getMessageToShow } from 'actions/index';
import QuestionAnswerWrapper from 'containers/deliverydetails/questionAnswerWrapper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { SEMANTIC_TYPES } from 'constants.js';
import { QuestionLabels } from 'containers/common/innovel_util';

const iconStyles = {
  selectIcon: {
    color: "#3071A9"
  }
};
const CustomExpandMore = withStyles(iconStyles)(
  ({ className, classes, ...rest }) => {
    return (
      <ExpandMoreIcon
        {...rest}
        fontSize='large'
        className={clsx(className, classes.selectIcon)}
      />
    );
  }
);

export class StringDropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.qa.answer.newValue,
      selectOpen: false,
      labels: QuestionLabels(SEMANTIC_TYPES, this.props.qa.question.semanticType)
    };
    this.errorRef = React.createRef();
  }

  selectChoice = e => {
    const selected = e.target.value;
    this.props.clearErrorId(this.props.qa.question.id);
    this.props.clearErrorId(this.props.qa.question.subQuestionAnswers[0].question.id);
    this.state.StringDropDown &&
      Array.isArray(this.props.qa.question.subQuestionAnswers[0].question) &&
      this.props.qa.question.subQuestionAnswers.forEach(subQa => {
        this.props.clearErrorId(subQa.question.id);
      })
    Array.isArray(this.props.qa.question.subQuestionAnswers) &&
      this.props.qa.question.subQuestionAnswers.forEach(subQa => {
        let ans = null;
        if (subQa.question?.answerType === 'TEXT_BOX') {
          if (selected === 'Gate Pass') {
            ans = subQa.answer.currentValue;
          }
        }
        this.props.updateData(ans, subQa.question.id, subQa.question.semanticType);
      })
    this.setState({ selected }, () => {
      this.props.updateData(
        selected,
        this.props.qa.question.id,
        this.props.qa.question.semanticType
      );
    })
  };

  render() {
    const { errorIDs = [] } = this.props;
    const error = _.find(errorIDs, ['id', this.props.qa.question.id]);
    if(error && error.rank == errorIDs[0].rank && this.errorRef.current !== null) {
      this.errorRef.current.focus();
    }
    return (
      <div style={{marginBottom: "18px", marginTop: "15px"}}>
        {
          <div className='orderBoxWidth'>
            <label className="text-xxsmall" id={this.state.labels.subQuestionLabel}>
              {' '}
              {this.props.qa.question.question}
              {this.props.qa.question.note && (
                <p>{this.props.qa.question.note}</p>
              )}
            </label>
            <FormControl fullWidth error={error} className='stringCapitalize'>
              <InputLabel id="select-label">Entrance Method</InputLabel>
              <Select
                labelId="select-label"
                id="entrnace-method"
                variant="outlined"
                value={this.state.selected}
                label="Entrance Method"
                onChange={this.selectChoice}
                IconComponent={CustomExpandMore}
                inputProps={{
                  inputRef: this.errorRef,
                  "aria-describedby": this.state.labels.subQuestionLabel
                }}
              >
                {this.props.qa.question.choices[0].map((choice, index) => {
                  return (<MenuItem key={index} value={choice.value}>{choice.value}</MenuItem>)
                })}
              </Select>
            </FormControl>
            {error && (
              <span id='invalidOption' style={{ color: '#D32029', marginLeft: '12px', fontSize: '12px', fontWeight: '700' }}>You must select an option.</span>
            )}
            <span>
              {this.props.qa.question.choices[0].map((choice, index) => {
                if (
                  this.state.selected
                  && this.state.selected === choice.value
                  && choice.question
                  && choice.question.id
                ) {
                  return this.props.qa.question.subQuestionAnswers.map(
                    (qa, index) => {
                      if (qa.question.id === choice.question.id) {
                        return <QuestionAnswerWrapper key={index} qa={qa} errorIDs={this.props.errorIDs} clearErrorId={this.props.clearErrorId} />;
                      }
                    }
                  );
                }
              })}
            </span>
          </div>
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ updateData, getMessageToShow }, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(StringDropDown);

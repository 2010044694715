import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const TimeWindowModal = ({
  showModal,
  handleClose,
  minutes,
  seconds,
  timeOutType
}) => {
  return (
    <Modal show={showModal}>
      <Modal.Header>
        <Modal.Title>{timeOutType.charAt(0).toUpperCase() + timeOutType.slice(1).toLowerCase()} Reminder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {timeOutType} will time out in {minutes}:{seconds} before bringing you back to the start of the process.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
import { UTC_TIME_FETCH } from 'actions';
import moment from 'moment';

export default function (state = { datetime: moment().format, unixtime: moment().utc().format('x') }, action) {
    switch (action.type) {
        
        case UTC_TIME_FETCH: {
            if(action &&
            action.payload &&
            action.payload.data) {
                return action.payload.data;
            }
        }
        default:
            return state;
    }
}
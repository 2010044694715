import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import styles from 'styles.css.js';

class InnovelFlashMessage extends Component {

  componentDidUpdate() {
    if (this.props.messageObj.alertType === 'error') {
      //window.scrollTo(0, 0);
    }
  }

  render() {
    const { message, alertType } = this.props.messageObj;
    return (
      <div style={styles.onlyFontstyleElement}>
        <div
          style={
            alertType === 'reset' &&
              !message ? (
              { visibility: 'hidden', display: 'none' }
            ) : (
              { display: 'none' }
            )
          }
        >
          <Alert color="success">{'Placeholder'}</Alert>
        </div>
        {alertType === 'success' && message && (
          <div>
            <Alert color="success">{message}</Alert>
          </div>
        )}
        {alertType === 'error' && message && (
          <div>
            <Alert style={{border: "2px solid #d0222f", backgroundColor: "#FDD6D6", borderRadius: "3px", font: "Helvetica Neue", color: "#333333"}}>{message}</Alert>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    messageObj: state.flashMessage
  };
}

export default connect(mapStateToProps, null)(InnovelFlashMessage);

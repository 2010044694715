import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Stack from '@mui/material/Stack';
import Button from './common/ButtonComponent.js';
import { getTransactionDetails, getMessageToShow, getUTCTime, getBannerMaintenanceAlert } from 'actions/index';
import { withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/self_scheduler/_scheduleResult.scss';
import { apiRepo } from 'repository/apiRepo';
import { TrackingSpinner } from 'containers/common/tracking_spinner';
import {
  clearSessionStorage,
  getTransactionRef,
  phoneNoFormat
} from 'containers/common/innovel_util';
import moment from 'moment';
import parse from 'html-react-parser';

class ScheduleResult extends Component {
  constructor(props) {
    super(props);
    this.topReference = React.createRef();

    const transID = getTransactionRef();
    if (transID === null) {
      this.state = {
        transactionID: ''
      };
    } else {
      this.state = {
        transactionID: transID
      };
    }
  }

  componentDidMount() {
    this.props.getBannerMaintenanceAlert();
    document.title = "Schedule My Delivery or Return Pickup | Costco Logistics"
    this.topReference.current.scrollIntoView();
    if (
      this.state.transactionID === '' ||
      this.state.transactionID === undefined ||
      this.state.transactionID === null
    ) {
      this.props.history.push('/');
    } else {
      //call the time api to get the utc time from server
      this.props.getUTCTime().then(result => {
        const httpStatus = result.payload.status
        if(httpStatus === 200) {
          //var response = result.payload.data;
          sessionStorage.setItem("utcTime", result.payload.request.response)
        }
        else {
          sessionStorage.removeItem("utcTime");
        }    
        //Get the support number before submitting the schedule request
        this.getSupportNumber(); 
        this.timer1 = setInterval(() => this.getItems(), 1000);
        this.timer2 = setInterval(() => this.setLimit(), 35000);
      }).catch(error => {
        this.getSupportNumber();
        this.timer1 = setInterval(() => this.getItems(), 1000);
        this.timer2 = setInterval(() => this.setLimit(), 35000);
      })

      this.setState({
        percent: 1,
        orderComplete: false,
        progressText: ''
      });
    }
  }

  displayErrorMessage = (_orderComplete, _errorState, _noData, message) => {
    this.setState({
      orderComplete: _orderComplete,
      errorState: _errorState,
      noData: _noData,
      progressText: parse(message)
    });
  }

  getItems = () => {
    if (this.state.orderComplete !== true) {
      apiRepo
        .serviceCall(
          'get',
          `/v1/schedule/getStatus/${this.state.transactionID}`
        )
        .then(result => {
          let percent;
          let text;
          let orderComplete = false;
          if(result !== null || result !== undefined) {
            const httpStatus = result.status;
            if (httpStatus === 200 || httpStatus === 400 || httpStatus === 500) {
              if (result.data.serviceResponse.statusId === 7) {
                if (this.state.percent < 20) {
                  percent = this.state.percent + 15;
                  text = 'Started Scheduling...';
                }

                if (20 < this.state.percent < 80) {
                  const completedOrders = result.data.serviceResponse.submitOrderResult.filter(
                    order => order.statusId === 9 || order.statusId === 11
                  );
                  if (completedOrders.length > 0) {
                    let noOfOrders =
                      result.data.serviceResponse.submitOrderResult.length;
                    let noOfCompletedOrders = completedOrders.length;
                    let percentIncrease = 50 / noOfOrders * noOfCompletedOrders;
                    percent = this.state.percent + percentIncrease;
                    text =
                      'Submitting Orders... ' +
                      noOfCompletedOrders +
                      '/' +
                      noOfOrders +
                      ' Completed.';
                  } else if (
                    completedOrders.length === 0 &&
                    this.state.percent < 70
                  ) {
                    percent = this.state.percent + 5;
                    text = 'Submitting Orders... ';
                  }
                }
              } else if (
                result.data.serviceResponse.statusId === 9 ||
                result.data.serviceResponse.statusId === 11
              ) {
                const { description, statusId } = result.data.serviceResponse;
                const { customerEmail } = this.props.transactionDetails;
                percent = 100;
                if(statusId === 9) {
                  text =  description.includes('EMAILPLACEHOLDER') ? description.replace('EMAILPLACEHOLDER', customerEmail) : description + ' ' + customerEmail;
                } else {
                  text =  description.includes('EMAILPLACEHOLDER') ? description.replace('EMAILPLACEHOLDER', customerEmail) : description;
                }
                orderComplete = true;
                localStorage.removeItem("bulkUrl");
                localStorage.removeItem("key");
                clearSessionStorage();
              }
              if (percent) {
                this.setState({
                  percent: percent,
                  orders: result.data.serviceResponse.submitOrderResult,
                  orderMap: result.data.serviceResponse.submitOrderResultMap
                });
                this.displayErrorMessage(
                  orderComplete, 
                  result.data.serviceResponse.statusId === 9 ? false : true, 
                  false, 
                  text
                );
              }        
            }
            else {
              this.displayErrorMessage(
                true,
                true,
                false,
                process.env.REACT_APP_MSC_CONTACT_US
              );
              //setInterval(() => this.props.history.push('/'), 10000);
            }
          }
          else {
            this.displayErrorMessage(
              true,
              true,
              false,
              process.env.REACT_APP_MSC_CONTACT_US
            );
            //setInterval(() => this.props.history.push('/'), 10000);
          }
        }).catch(error => {
          this.displayErrorMessage(
            true,
            true,
            false,
            process.env.REACT_APP_MSC_CONTACT_US
          );
        });
    }
  };

  getSupportNumber = () => {
    if (!this.state.orderComplete) {
      this.props.getTransactionDetails(this.state.transactionID, 5)
        .then(result => {
          const httpStatus = result.error === undefined ? result.payload.status : result.payload.response !== undefined ? result.payload.response.status : result.payload.request.status;
          if (httpStatus === 200 || httpStatus === 400 || httpStatus === 500) {
            const status = result.error === undefined ? result.payload.data.status : result.payload.response.data.status;
            if(status !== null || status !== undefined) {
              const validStatusCode = status.statusCode != null ? "True" : "False";
              if(status.statusCode === '00' && validStatusCode) {
                let scheduleResult = result.payload.data.serviceResponse;
                this.setState({ clientCode: scheduleResult.clientCode })
                this.setState({ mscPhone: scheduleResult.workOrderList[0].msc_phone_number })
              }
              else {
                const errorMessage = result.error === undefined ? result.payload.data.errorDetails.errors[0].message : result.payload.response.data.errorDetails.errors[0].message;
                this.displayErrorMessage(
                  true,
                  true,
                  true,
                  errorMessage
                );
                //setInterval(() => this.props.history.push('/'), 10000);
              }              
            }
            else {
              this.displayErrorMessage(
                true,
                true,
                true,
                process.env.REACT_APP_MSC_CONTACT_US
              );
              //setInterval(() => this.props.history.push('/'), 10000);
            }
          }
        });
    }
  }

  setLimit = () => {
    if (!this.state.orderComplete) {
      this.setState({
        orderComplete: true,
        errorState: true,
        percent: 100,
        progressText: parse(process.env.REACT_APP_MSC_CONTACT_US)
      });
      let messageObj = {
        alertType: 'error',
        message: parse(process.env.REACT_APP_MSC_CONTACT_US)
      };
      this.props.getMessageToShow(messageObj);
    }
  };

  componentDidUpdate(prevState, prevProps) {
    if (prevState.orderComplete !== true && this.state.orderComplete === true) {
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      this.timer = null;
    }
  }

  renderConfirmationOrders = () => {
    const { workOrderList, loading } = this.props.transactionDetails;

    if(!loading && workOrderList !== null) {
      return(
        <div>
          {workOrderList.map((order) => (
            <div style={{marginBottom: '1rem'}}>
              <div style={{border: '1px solid #909090', backgroundColor: '#FAFAFA', marginTop: '20px', boxShadow: '2px 2px 3px #ccc', fontFamily: 'Helvetica Neue'}}>
                <div className="orderBodyPanel" style={{marginTop: "15px"}}>
                  <div className="row" style={{marginBottom: "10px"}}>
                    <div className="col">
                      <span style={{fontWeight: "bold"}}>Order Number</span><br role="presentation" />
                      {order.trackingOrderList[0].workOrderNumber || 'Not Available'}
                    </div>
                  </div>
                  <div className="row" style={{marginBottom: "10px"}}>
                    <div className="col">
                      <span style={{fontWeight: "bold"}}>Order Date</span><br role="presentation" />
                      {order.trackingOrderList[0].orderCreatedDate}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span style={{fontWeight: 'bold'}}>Tracking Number(s)</span><br  role="presentation" />
                        <ul style={{listStyleType: 'none', padding: 0}}>
                          {order.trackingOrderList.map(tracking => 
                            <div>
                              {tracking.workOrderNumber === order.workOrderNumber &&
                              (<li>{tracking.trackingNumber}</li>)}
                            </div>
                          )}
                        </ul>                
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }

  handleCheckbox = e => {
    // e.preventDefault();
  };

  render() {
    return (
      <div className="container-fluid main-grid" style={{ minHeight: '90vh', marginBottom: "1rem" }} ref={this.topReference} role="main">
        <div className="row">
          <div className="col-lg-10 offset-md-1 offset-lg-2">
            <div id="mains" className="sr-only" tabIndex="-1"></div>
            <div id="banner">
              <h1 className="t1">
                Scheduled Date Confirmation
              </h1>
              {' '}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container reportMainContainer" style={{ maxWidth: "720px", marginBottom: "24px" }}>
            <div className="row">
              <div className="col">
                {!this.state.orderComplete && (
                  <div className="mainProgressContainer">
                    <TrackingSpinner />
                  </div>)
                }
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-9 col-sm-6 offset-sm-1 offset-md-1 offset-lg-2 result-ele">
            {this.state.orderComplete && (
              <div className={!this.state.errorState ? "mainTextContainer" : "mainErrorTextContainer"}>{this.state.progressText}</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5 offset-sm-1 offset-md-1 offset-lg-2 result-ele">
            {this.state.orderComplete && !this.state.errorState && !this.state.noData && this.renderConfirmationOrders()}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5 offset-sm-1 offset-md-1 offset-lg-2 result-ele">
            {this.state.orderComplete && !this.state.errorState && !this.state.noData && (
              <div style={{border: '1px solid #909090', backgroundColor: '#FAFAFA', marginTop: '20px', marginBottom: '20px', boxShadow: '2px 2px 3px #ccc', fontFamily: 'Helvetica Neue'}}>
                <div  className="orderBodyPanel" style={{marginTop: "15px"}}>
                  <div className="row" style={{marginBottom: "10px"}}>
                    <div className="col">
                      <span style={{fontWeight: 'bold'}}>Requested Delivery/Return Pickup Date</span><br role="presentation" />
                      {
                        this.props.transactionDetails.scheduleDate != null ? 
                        moment(this.props.transactionDetails.scheduleDate).format('LLLL').substring(0, moment(this.props.transactionDetails.scheduleDate).format('LLLL').length - 9) 
                        : 'Not Available'
                      }
                    </div>
                  </div>
                  {((this.props.transactionDetails.startTime !== "" && this.props.transactionDetails.startTime !== null) &&
                    this.props.transactionDetails.timeWindowFlag) && <div className="row" style={{marginBottom: "10px"}}>
                    <div className="col">
                      <span style={{fontWeight: 'bold'}}>Requested Time Window</span><br role="presentation" />
                      {
                        this.props.transactionDetails.startTime != null ? 
                        moment.utc(this.props.transactionDetails.startTime, ["HH:mm"]).format('h:mm a') + " - " + moment.utc(this.props.transactionDetails.endTime, ["HH:mm"]).format('h:mm a') 
                        : 'Not Selected'
                      }
                    </div>
                  </div>}
                  <div className="row" style={{marginBottom: "10px"}}>
                    <div className="col">
                      <span style={{fontWeight: 'bold'}}>Address</span><br role="presentation" />
                      {this.props.transactionDetails.shipToAddress1 + ', ' + this.props.transactionDetails.shipToCity + ', ' + this.props.transactionDetails.shipToState + ' ' + this.props.transactionDetails.shipToZip}
                    </div>
                  </div>
                  <div className="row" style={{marginBottom: "10px"}}>
                    <div className="col">
                      <span style={{fontWeight: 'bold'}}>Email</span><br role="presentation" />
                      {this.props.transactionDetails.customerEmail}
                    </div>
                  </div>
                  <div className="row" style={{marginBottom: "10px"}}>
                    <div className="col">
                      <span style={{fontWeight:'bold'}}>Phone</span><br role="presentation" />
                      {phoneNoFormat(this.props.transactionDetails.shipToPhone) || 'XXX-XXX-XXXX'}
                      <br role="presentation" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span style={{fontWeight:'bold'}}>Alternate Phone</span><br role="presentation" />
                      {this.props.transactionDetails.shipToPhone !== this.props.transactionDetails.workOrderList[0].trackingOrderList[0].phone ? 
                      phoneNoFormat(this.props.transactionDetails.workOrderList[0].trackingOrderList[0].phone) : 
                      'None Provided'}
                      <br role="presentation" /><br role="presentation" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-9 col-sm-12 col-xs-12 offset-md-1 offset-lg-2" style={{marginTop: "20px"}}>
            {this.state.orderComplete && !this.state.errorState && !this.state.noData && (
              <div>
                <Stack 
                  direction="row" 
                  justifyContent={{xs: 'center', sm: 'right', md: 'right', lg: 'right'}}
                  alignItems={{xs: 'center', sm: 'right', md: 'right', lg: 'right'}}
                >
                  <Button
                    disableRipple
                    role="link"
                    fullWidth
                    variant="contained"
                    sx={{backgroundColor: '#3071a9', minWidth: 260, maxWidth: 400, textTransform: 'capitalize', fontSize: '16px'}}
                    onClick={() => this.props.history.push('/userselfschedule')}
                    btnText={'Schedule Another Date'}
                  />
                </Stack>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getTransactionDetails, getMessageToShow, getUTCTime, getBannerMaintenanceAlert }, dispatch)
  };
};

function mapStateToProps(state) {
  return {
    transactionDetails: state.transactionDetails
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleResult)
);

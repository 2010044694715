import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import styles from 'styles.css.js';
import 'styles/self_scheduler/_startPage.scss';
import parse from 'html-react-parser';
import { bindActionCreators } from 'redux';
import { getOrderServiceMaintenanceAlert } from 'actions';

class InnovelServiceAlert extends Component {

  componentDidUpdate() {
    if (this.props.messObj.alertType === 'Maintenance') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { alertType, message } = this.props.messObj;
    return (
      <div style={styles.onlyFontstyleElement}>
        {alertType === 'Maintenance' && message && (
          <Alert color="success">{parse(message)}</Alert>
        )}
      </div>
    );
  }
}


export default InnovelServiceAlert;


import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from 'styles.css.js';
import 'styles/self_scheduler/_startPage.scss';
import parse from 'html-react-parser';
import { bindActionCreators } from 'redux';
import { getBannerMaintenanceAlert } from 'actions';

class AlertComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayResults: false,
      displayMessage: ''
    };
  }

  componentDidUpdate() {
    if (this.state.displayMessage !== this.props.messageObj.message) {
      this.setState({
        displayResults: true,
        displayMessage: this.props.messageObj.message,
        alertType: this.props.messageObj.alertType
      });
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div style={styles.onlyFontstyleElement}>
        <div className="centerAlign warning-message-wrapper"
          style={
            this.state.alertType === 'Maintenance' &&
              this.state.displayMessage !== '' &&
              this.state.displayResults ? (
              {}
            ) : (
              { display: 'none' }
            )
          }
        >
          {this.state.displayMessage && (<div className='warning-message'>{parse(this.state.displayMessage)}</div>)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    messageObj: state.maintenanceAlertMessage.bannerMessage
  };
}


export default connect(mapStateToProps, null)(AlertComponent);

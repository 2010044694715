import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'styles/_deliveryform.scss';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'styles/_font.scss';
import { getTransactionDetails, getMessageToShow, getUTCTime, getBannerMaintenanceAlert } from 'actions/index';
import { bindActionCreators } from 'redux';
import { TrackingSpinner } from 'containers/common/tracking_spinner';
import LogoffLayer from './logoffLayer';
import { getTransactionRef, clearSessionStorage } from 'containers/common/innovel_util';
import NewDeliveryDetailsForm from 'containers/deliverydetails/newdeliverydetailsform';
import VideoModal from 'containers/deliverydetails/videomodal';
import Stack from '@mui/material/Stack';
import Button from './common/ButtonComponent.js';
import Link from '@mui/material/Link';
import TimeWindowTimer from './timeWindowTimer';
import moment from 'moment';
import { TimerExpirationModal } from 'containers/common/timerExpirationModal.js';
import parse from 'html-react-parser';

class DeliveryDetails extends Component {
  constructor(props) {
    super(props);
    const transID = getTransactionRef();
    this.detailsScrollView = React.createRef();
    this.state = {
      transactionID: transID,
      schedulingOrder: false,
      isOpen: false,
      startSpinner: true,
      loading: false,
      disableBtn: false,
      timeWindowEligible: false,
      dceWindowsFound: false,
      twTimerExpired: false
    };
  }

  componentDidMount() {
    this.props.getBannerMaintenanceAlert();
    document.title = "Schedule My Delivery or Return Pickup | Costco Logistics"
    this.detailsScrollView.current.scrollIntoView();
    if (
      this.state.transactionID !== '' &&
      this.state.transactionID !== undefined &&
      this.state.transactionID !== null
    ) {  

      this.props.getUTCTime().then(result => {
        const httpStatus = result.payload.status
        if(httpStatus === 200) {
          //var response = result.payload.data;
          sessionStorage.setItem("utcTime", result.payload.request.response)
        }
        else {
          sessionStorage.removeItem("utcTime");
        }    
        this.props
        .getTransactionDetails(this.state.transactionID, 2)
        .then(result => {
          const httpStatus = result.error === undefined ? result.payload.status : result.payload.response !== undefined ? result.payload.response.status : result.payload.request.status;
          if (httpStatus === 200 || httpStatus === 400 || httpStatus === 500) {
            const status = result.error === undefined ? result.payload.data.status : result.payload.response.data.status;
            if (status !== null || status !== undefined) {
              const validStatusCode = status.statusCode != null ? "True" : "False";
              if (status.statusCode === '00' && validStatusCode) {

                //set client name and phone for customer support message
                this.setState({
                  clientCode: result.payload.data.serviceResponse.trackingOrder[0].clientCode,
                  mscPhone: result.payload.data.serviceResponse.trackingOrder[0].msc_phone_number,
                  timeWindowEligible: result.payload.data.serviceResponse.timeWindowFlag
                });

                if (
                  result.payload.data &&
                  result.payload.data.serviceResponse &&
                  result.payload.data.serviceResponse.orderDeliveryDetails &&
                  result.payload.data.serviceResponse.orderDeliveryDetails
                    .subOrderDeliveryDetailsList
                ) {
                  this.setState({
                    startSpinner: false
                  });

                  if(result.payload.data.serviceResponse.timeWindowFlag) {
                    if(localStorage.getItem("timerStartTime") && localStorage.getItem("dceWindows")) {
                      this.setState({ dceWindowsFound: localStorage.getItem("dceWindows")});
                      if(JSON.parse(localStorage.getItem("dceWindows")) === true && 
                      moment.utc(Date.now()).diff(localStorage.getItem("timerStartTime"), 'minutes', true) > 3.05) {
                        this.setState({ twTimerExpired: true, dceWindowsFound: false });
                      }
                    } else {
                      if(localStorage.getItem("dceWindows") && JSON.parse(localStorage.getItem("dceWindows")) === false) {
                        this.setState({ dceWindowsFound: false});
                      } else {
                        this.props.history.push('/userselfschedule?timeoutWindow=True');
                      }                      
                    } 
                  }
                } else {
                  this.setState({ errorFlag: true, apiErrorMessage: 'Unable to fetch the delivery details. Try to refresh the page or come back later.' });
                  const messageObj = {
                    alertType: 'error',
                    message:
                      'Unable to fetch the delivery details. Try to refresh the page or come back later.'
                  };
                  this.props.getMessageToShow(messageObj);
                }
              }
              else {
                const apiErrorMessage = (result.error === undefined)
                  ? parse(result.payload.data.errorDetails?.errors?.[0]?.message)
                  : parse(result.payload.response.data.errorDetails?.errors?.[0]?.message);

                const messageObj = {
                  alertType: 'error',
                  message: apiErrorMessage || process.env.REACT_APP_MSC_CONTACT_US,
                };
                this.setState({ errorFlag: true, startSpinner: false, apiErrorMessage: apiErrorMessage || messageObj.message });
                this.props.getMessageToShow(apiErrorMessage);
                return;
              }
            }
          } else {
            this.setState({ errorFlag: true, apiErrorMessage: parse(process.env.REACT_APP_MSC_CONTACT_US), startSpinner: false });
          }
        }).catch(error => {
          let messageObj = {
            alertType: 'error',
            message: process.env.REACT_APP_MSC_CONTACT_US
          };
          if (error?.response?.data?.status?.statusCode !== '00') {
            messageObj.message = error.response.data?.errorDetails?.errors?.[0]?.message
          }
          this.props.getMessageToShow(messageObj)
          this.setState({ apiErrorMessage: parse(messageObj.message) });
        });
      }); 
    } else {
      this.props.history.push('/');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.transactionDetails !== undefined &&
      nextProps.transactionDetails.resultFound === false
    ) {
      var messageObj = {
        alertType: 'error',
        message:
          'Unable to fetch the details. Try to refresh the page or come back later.'
      };
      this.props.getMessageToShow(messageObj);
    } else if (
      nextProps.transactionDetails !== undefined &&
      nextProps.transactionDetails.trackingOrder.length !== 0
      //&& nextProps.transactionDetails.allowToSchedule)
    ) {
      this.setState({
        schedulerOrdType:
          nextProps.transactionDetails.scheduleTransactionDetail !==
            undefined ||
            nextProps.transactionDetails.scheduleTransactionDetail !== null
            ? nextProps.transactionDetails.scheduleTransactionDetail
              .scheduleOrderType
            : 0
      });
      if (
        nextProps.transactionDetails.scheduleTransactionDetail
          .scheduleOrderType === 5
      ) {
        //Amazon orders
        this.setState({
          stepNum: 1,
          custService: nextProps.transactionDetails.custService,
          schedulerOrdType: 5
        });
      } else if (
        nextProps.transactionDetails.scheduleTransactionDetail
          .scheduleOrderType === 4
      ) {
        //Normal orders
        this.setState({ stepNum: 2, schedulerOrdType: 4 });
      } else {
        if (nextProps.transactionDetails.custService === undefined) {
          let messageObj = {
            alertType: 'error',
            message: 'Order cannot be scheduled On-Line.'
          };
          this.props.getMessageToShow(messageObj);
        } else {
          let messageObj = {
            alertType: 'error',
            message: process.env.REACT_APP_MSC_CONTACT_US
          };
          this.props.getMessageToShow(messageObj);
        }
      }
    }
  }

  openModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  closeExpirationModal = () => {
    clearSessionStorage();
    this.props.history.push('/userselfschedule?timeoutWindow=True');
  }

  acceptMethods = storeDeliveryDetails => {
    // Parent stores the method that the child passed
    this.storeDeliveryDetails = storeDeliveryDetails;
  };

  handleDisableBtn = flag => {
    this.setState({      
      disableBtn: flag
    });
  }

  handleClick = () => {
    this.props.getUTCTime().then(result => {
      const httpStatus = result.payload.status
      if(httpStatus === 200) {
        //var response = result.payload.data;
        sessionStorage.setItem("utcTime", result.payload.request.response)
      }
      else {
        sessionStorage.removeItem("utcTime");
      }  
      this.next()
    }).catch(error => {
      sessionStorage.removeItem("utcTime");
      this.next()
    }); 
  }

  next = e => {
    // To avoid multiple button clicks
    if(this.state.disableBtn) {
      return
    }
    this.state.disableBtn = true;
        
    // Execution
    this.setState({ loading: true });
    this.storeDeliveryDetails()
      .then(result => {
        this.setState({ loading: false, disableBtn: false });
        if (result) {
          const serviceResp = result.data?.serviceResponse;
          const navigation = serviceResp?.scheduleTransactionDetail?.pageNavigation;
          let nextPage = null;
          Object.keys(navigation).forEach(page => {
            if (navigation[page] === 2) {
              nextPage = page;
            }
          })
          this.props.history.push(nextPage);
        }
      })
      .catch(error => {
        this.setState({ loading: false, disableBtn: false });
      })
  };

  render() {
    return (
      <div style={{ minHeight: '100vh' }} ref={this.detailsScrollView}>
        <div className="main-grid delivery-body" role="main">
          <div className="row">
            <div id="mains" className="sr-only" tabIndex="-1"></div>
            <div className="col-lg-8 offset-md-1 offset-lg-2 delivery-header">
              <div aria-labelledby='banner' id="banner">
                <h1 className="t1" style={{ marginBottom: '8px' }} aria-label='Step 2 of 3 - Questionnaire.'>
                  Step 2 of 3 - Questionnaire
                </h1>
                {' '}
              </div>
            </div>
            {(!this.state.apiErrorMessage && (<div className="col-lg-8 offset-md-1 offset-lg-2 delivery-header" style={{ display: this.state.startSpinner ? 'none' : 'block' }}>
              <p className="body-copy">Answer a few required questions to ensure your delivery or return pickup meets your expectations.
                If you need assistance, contact{' '}
                <a target='_blank' rel='noopener noreferrer' href='https://customerservice.costco.com/app/answers/GuidedAssistant/a_id/1001241/loc/en_US/g_id/78'>Customer Service</a>.
              </p>
              <p className="body-copy">Do not wait until the delivery or return pickup day to find out it does not fit. Make sure your item will fit through the pathway and in its final location to ensure a successful delivery or return pickup. {' '}
                {!this.state.isOpen && (
                  <button
                    autoFocus
                    id="walk_the_path_video"
                    className="text-xsmall walkTPButton"
                    onClick={this.openModal}
                    style={{ 
                      cursor: 'pointer', 
                      verticalAlign: 'middle', 
                      fontWeight: '400', 
                      fontSize: '14px', 
                      lineHeight: '28px'}}
                  >
                    Walk the path and measure now
                  </button>
                )}.</p>
              {this.state.isOpen && (
                <div>
                  <VideoModal                    
                    isOpen={this.state.isOpen}
                    closeVideo={this.openModal}
                  />
                </div>
              )}
            </div>))}
            <div className='col-lg-10 offset-md-1 offset-lg-2'>
              {this.state.errorFlag &&
                <div
                  className="error-qna">
                  {this.state.apiErrorMessage}</div>}
            </div>
            <div className="col-md-5 col-lg-4 offset-md-1 offset-lg-2">
              {!this.state.twTimerExpired && <LogoffLayer />}
              {this.state.twTimerExpired && 
              <TimerExpirationModal 
              timeOutType={"Scheduling"}
              showModal={true}
              startingTime={moment(localStorage.getItem("timerStartTime")).format("h:mm a") }
              handleClose={this.closeExpirationModal}
              />}
              {this.state.timeWindowEligible && JSON.parse(this.state.dceWindowsFound) && !this.state.twTimerExpired ? <TimeWindowTimer /> : ""}
            </div>
          </div>
          <div className="row" role="form">
            <div className="col-lg-8 offset-md-1 offset-lg-2">
              {(!this.state.apiErrorMessage && (<hr aria-hidden="true" style={{ marginBottom: '24px', marginTop: '12px', display: this.state.startSpinner ? 'none' : 'block' }} />))}
              {this.state.startSpinner &&
                <div style={{ marginTop: '10vh', width: '100%', justifyContent: 'center' }} ref={this.topReference}>
                  <div className="centerAlign" style={{ flexDirection: 'column' }}>
                    {<TrackingSpinner />}
                  </div>
                </div>
              }
              {!this.state.startSpinner && <NewDeliveryDetailsForm
                shareMethods={this.acceptMethods}
                history={this.props.history}
                transactionRef={this.state.transactionID}
                orderDeliveryDetails={this.props.transactionDetails}
                disableBtn={this.handleDisableBtn}
              />
              }
              {(!this.state.apiErrorMessage && (<hr aria-hidden="true" style={{ margin: '24px 0px', display: this.state.startSpinner ? 'none' : 'block' }} />))}
            </div>
          </div>
          <div className="row">
            {(!this.state.apiErrorMessage && !this.state.startSpinner) && (<div className="col-lg-8 offset-md-1 offset-lg-2">
              <div>
                <Stack
                  direction={{ xs: 'column-reverse', sm: 'row', md: 'row', lg: 'row' }}
                  spacing={{ xs: 2, sm: 2, md: 4, lg: 4 }}
                  justifyContent={{ xs: 'center', sm: 'right', md: 'right', lg: 'right' }}
                  alignItems={{ xs: 'center', sm: 'right', md: 'right', lg: 'right' }}
                  fullWidth={{ xs: 'true', sm: 'true' }}
                  padding={{ lg: 3 }}
                >
                  <Link
                    href="tracking_schedule" 
                    underline="hover"
                    sx={{color: '#0060A9'}}
                  >
                    Back
                  </Link>
                  <Button
                    disableRipple
                    variant="contained"
                    role="link"
                    sx={{ backgroundColor: '#3071a9', minWidth: 265, maxWidth: 400, textTransform: 'capitalize', fontSize: "16px" }}
                    disabled={this.state.btnDisable}
                    onClick={!this.state.disableBtn ? this.handleClick : null}
                    btnText='Review Information'
                  />
                  {this.state.loading &&
                    <div>
                      <TrackingSpinner />
                    </div>
                  }
                </Stack>
              </div>
              <br role="presentation" /><br  role="presentation" />
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selfScheduleResponse: state.selfScheduleResponse,
    transactionDetails: state.transactionDetails
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getTransactionDetails, getMessageToShow, getUTCTime, getBannerMaintenanceAlert }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetails);
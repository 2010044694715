import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'styles/_deliveryform.scss';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'styles/_font.scss';
import { updateData, getMessageToShow } from 'actions/index';
import NumberDropDown from './numberDropDown';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Zoom from '@mui/material/Zoom';
import { styled } from "@mui/material/styles";
import _ from 'lodash';
import { SEMANTIC_TYPES } from 'constants.js';
import { QuestionLabels } from 'containers/common/innovel_util';

const CustomButton = styled(Button)(({ theme }) => ({
  display: "block",
  textTransform: "none",
  border: "none",
  padding: "0px",
  '&.MuiButton-root': { 
    '&:hover': {
      backgroundColor: "#FFFFFF !important"
    },
    '&:focus': {
      backgroundColor: "#FFFFFF !important"
    }  
  }
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    backgroundColor: '#F7F7F7',
    margin: '-10px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#333333',
    border: '1px solid #333333',
    PointerEvent: "auto"
  },
});

export class HaulAway extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yesNo: props.qa.answer.newValue,
      answerCheckBox: props.subQAanswer,
      displayQuestion: false,
      open: false,
      labels: QuestionLabels(SEMANTIC_TYPES, this.props.qa.question.semanticType),
      toolTip: ""
    };
    this.errorRef = React.createRef();
    this.trackingNumbers = [];
  }

  // If HAUL_AWAY_ITEM(single) or HAUL_AWAY_BULK(bulk), answer array has haulAvailable as true value, then only we'll display haul_away question
  shouldDisplayQuestion = qa => {
    let result = false;
    if (qa
      && qa.question
      && qa.question.subQuestionAnswers
      && qa.question.subQuestionAnswers[0]
      && qa.question.subQuestionAnswers[0].question
      && qa.question.subQuestionAnswers[0].question.semanticType === 'HAUL_AWAY_ITEM'
      && qa.question.subQuestionAnswers[0].answer
    ) {
      result = qa.question.subQuestionAnswers[0].answer.haulAvailable;
    }
    if (qa
      && qa.question
      && qa.question.subQuestionAnswers
      && qa.question.subQuestionAnswers[0]
      && qa.question.subQuestionAnswers[0].question
      && qa.question.subQuestionAnswers[0].question.semanticType === 'HAUL_AWAY_BULK'
      && qa.question.subQuestionAnswers[0].question.subQuestionAnswers
      && qa.question.subQuestionAnswers[0].question.subQuestionAnswers[0]
    ) {
      result = qa.question.subQuestionAnswers[0].question.subQuestionAnswers[0].answer.some(ans => ans.haulAvailable)
      this.setState({ 
        toolTip: this.props.qa.question.subQuestionAnswers[0].question.note,
        tooltipNote: this.props.qa.question?.subQuestionAnswers?.[0]?.question?.tooltip
      });
    }
    return result;
  }

  componentDidMount() {
    const me = this;
    this.setState({ displayQuestion: me.shouldDisplayQuestion(me.props.qa) });    
  }

  handleYesNoChange = e => {
    this.props.clearErrorId(this.props.qa.question.id);
    if (Array.isArray(this.props.qa.question.subQuestionAnswers)) {
      if(this.props.qa.question.subQuestionAnswers[0].question.subQuestionAnswers != null || 
        this.props.qa.question.subQuestionAnswers[0].question.subQuestionAnswers != undefined) {
        this.getItems(this.props.qa.question.subQuestionAnswers[0].question.subQuestionAnswers[0].answer).map(
          (subAnswer, cIndex) => {
            subAnswer.newValue = null;
            //subAnswer.currentValue = null;
            return subAnswer;
          }
        );
      }
      else {
        this.props.qa.question.subQuestionAnswers[0].answer.newValue = null;
        //this.props.qa.question.subQuestionAnswers.answer.currentValue = null;
      }
      this.props.qa.question.subQuestionAnswers.forEach(subQa => {
        this.props.clearErrorId(subQa.question.id);
        if (Array.isArray(subQa.question.subQuestionAnswers)) {
          subQa.question.subQuestionAnswers.forEach(subSubQa => {
            this.props.clearErrorId(subSubQa.question.id)
          });
        }
      })
    }
    let userAnswer = e.target.value === 'true' ? true : false;
    this.setState({ yesNo: userAnswer }, () => {
      this.props.updateData(userAnswer, this.props.qa.question.id, this.props.qa.question.semanticType);
    });
    // revertTrackingChanges is making the checkbox set to false and number dropdown values to 0.
    this.revertTrackingChanges();
  };

  handleCheckBoxChange = (trackingNumber, e) => {
    this.errorRef = React.createRef();
    this.props.clearErrorId(this.props.qa.question.subQuestionAnswers[0].question.subQuestionAnswers[0].question.id)
    this.props.clearErrorId(this.props.qa.question.subQuestionAnswers[0].question.id);
    let newQa = { ...this.props.qa.question.subQuestionAnswers[0] };
    let answers = this.getItems(newQa.answer);
    answers = answers.map((answer, pIndex) => {
      if (answer.answer.trackingNumber === trackingNumber) {
        if (answer.answer.newValue === true || answer.answer.newValue === 'true') {
          answer.answer.currentValue = false;
          answer.answer.newValue = false;
          this.getItems(newQa.question.subQuestionAnswers[0].answer).map(
            (subAnswer, cIndex) => {
              if (subAnswer.answer.trackingNumber === trackingNumber) {
                subAnswer.newValue = null;
              }
              return subAnswer;
            }
          );
        } else {
          answer.answer.currentValue = true;
          answer.answer.newValue = true;
        }
      }
      return answer;
    });
    this.props.updateData(
      answers,
      this.props.qa.question.subQuestionAnswers[0].question.id,
      this.props.qa.question.subQuestionAnswers[0].question.semanticType
    );
    this.setState({ answerCheckBox: newQa });
  };

  revertTrackingChanges = () => {
    this.trackingNumbers.map(trackNumber => this.revertChanges(trackNumber));
  }

  revertChanges = (trackingNumber) => {
    let newQa = _.cloneDeep(this.props.qa.question.subQuestionAnswers[0]);
    let answers = this.getItems(newQa.answer);
    answers.map(answer => {
      if (answer.answer.trackingNumber === trackingNumber) {
        if (newQa.question.answerType === 'DROP_DOWN_NUMBER') {
          answer.currentValue = null;
          answer.newValue = null;
        } if (newQa.question.answerType === 'CHECK_BOX') {
          answer.answer.currentValue = false;
          answer.answer.newValue = false;
        }
        newQa.question.subQuestionAnswers && this.getItems(newQa.question.subQuestionAnswers[0].answer).map(
          subAnswer => {
            if (subAnswer.answer.trackingNumber === trackingNumber) {
              subAnswer.newValue = null;
            }
          }
        );
      }
      return answer;
    });
    if (!Array.isArray(newQa.answer)) {
      answers = answers[0];
    }
    this.props.updateData(
      answers,
      this.props.qa.question.subQuestionAnswers[0].question.id,
      this.props.qa.question.subQuestionAnswers[0].question.semanticType
    );
    this.setState({ answerCheckBox: newQa });
  };

  getItems = (items) => {
    if (Array.isArray(items))
      return items;
    else
      return [items];
  }

  handleOpen = () => {
    this.setState({open: true});
    this.handleTooltipOpen();
  }

  toggleClick = () => {
    var toolTipOpen = this.state.open;
    toolTipOpen = !toolTipOpen;
    this.setState({ open: toolTipOpen });

    if(toolTipOpen === true) {
      this.handleTooltipOpen();
    }
  }

  handleClose = () => {
    this.setState({open: false});
  }

  handleTooltipOpen = () => {
    var displayTime = 6;
    const intervalId = setInterval(() => {
      if(this.state.open) {
        displayTime -= 1;
        if(displayTime === 0) {
          this.setState({ open: false });
          clearInterval(intervalId);
        }
      } else {
        clearInterval(intervalId);
      }
    }, 1000);
  }

  returnDropdown = () => {
    const answer = this.props.qa.answer;
    if (answer) {
      this.trackingNumbers.push(answer.trackingNumber);
      return (
        <div >
          <div>
            {answer.currentValue === true &&
              this.props.qa.question.subQuestionAnswers[0].answer.answer.trackingNumber ===
              answer.trackingNumber &&
              <p>
                <NumberDropDown
                  trackingNumber={answer.trackingNumber}
                  qa={this.props.qa.question.subQuestionAnswers[0]}
                  errorIDs={this.props.errorIDs}
                  clearErrorId={this.props.clearErrorId}
                  errorTrackingNumbers={this.props.errorTrackingNumbers}
                />
              </p>
            }
          </div>
        </div>
      );
    }
  }

  render() {
    this.trackingNumbers = [];
    const { errorIDs = [] } = this.props;
    const error = _.find(errorIDs, ['id', this.props.qa.question.subQuestionAnswers[0].question.id])
    if(error && error.rank == errorIDs[0].rank && this.errorRef.current !== null) {
      this.errorRef.current.focus();
    }
    const singleOrder = this.getItems(this.props.qa.question.subQuestionAnswers[0].answer).length === 1;
    if (!this.state.displayQuestion) {
      return '';
    }
    const orderTrackingMap = {};
    if (this.getItems(this.props.qa.question.subQuestionAnswers[0].answer).length >= 1) {
      this.getItems(this.props.qa.question.subQuestionAnswers[0].answer).forEach(ans => {
        const workOrderNumber = ans.answer.workOrderNumber || 'Not Available';
        if (orderTrackingMap[workOrderNumber]) {
          orderTrackingMap[workOrderNumber].trackingNumberList.push(ans)
        } else {
          orderTrackingMap[workOrderNumber] = {
            trackingNumberList: [ans]
          }
        }
      });
    }

    return (
      <div>        
        <fieldset>
          <legend id={this.state.labels.label} className="text-xxsmall" style={{ marginBottom: '0px', fontSize: '14px' }}>
            <span title={this.props.qa.question.tooltip}>{this.props.qa.question.question}</span>
            {this.props.qa.question.note && <p>{this.props.qa.question.note}</p>}
          </legend>
          <Col>
            <Row sm="2">
              <FormControl>
                <RadioGroup
                  aria-labelledby={this.state.labels.label}
                  onChange={this.handleYesNoChange}
                  value={this.state.yesNo}
                >
                  <FormControlLabel
                    value={true}
                    style={{ marginBottom: "0" }}
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    style={{ marginBottom: "0" }}
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Row>
          </Col>
          {this.state.yesNo
            && this.getItems(this.props.qa.question.subQuestionAnswers[0].answer).length >= 1
            && this.props.qa.question.subQuestionAnswers[0].question.semanticType === 'HAUL_AWAY_BULK'
            && (<p>{this.props.qa.question.subQuestionAnswers[0].question.question}</p>)
          }
          {this.state.yesNo
            && this.getItems(this.props.qa.question?.subQuestionAnswers?.[0]?.answer).length >= 1
            && this.props.qa.question?.subQuestionAnswers?.[0]?.question?.semanticType === 'HAUL_AWAY_BULK'
            && this.props.qa.question?.subQuestionAnswers?.[0]?.question?.note !== 'NULL'
            && (
              <div role="tooltip">
                <CustomTooltip
                  aria-expanded={this.state.open}
                  open={this.state.open}
                  arrow
                  tabIndex={0}
                  aria-labelledby="tooltip"
                  describeChild
                  title={this.state.tooltipNote}
                  TransitionComponent={Zoom}
                  placement="top"
                  // enterTouchDelay={0}
                  // leaveTouchDelay={4000}
                  sx={{ maxWidth: {xs: "270px", sm: "450px", md: "450px", lg: "500px"}}}
                >
                  <CustomButton 
                  disableRipple 
                  onFocus={this.handleOpen} 
                  onClick={this.toggleClick} 
                  onBlur={this.handleClose} 
                  onMouseLeave={this.handleClose} 
                  onMouseOver={this.handleOpen}>
                    <label id='tooltip' className='noteTooltip'>{this.state.toolTip}</label>
                  </CustomButton>       
                </CustomTooltip>
              </div>
            )
          }
          {error && this.props.qa.question?.subQuestionAnswers?.[0]?.question?.subQuestionAnswers?.[0]?.question?.answerType === 'DROP_DOWN_NUMBER' && (
            <div className="err-msg-inbox orderBoxWidth">
              At least 1 tracking number must be selected.
            </div>
          )}
          {this.state.yesNo &&
            (Object.keys(orderTrackingMap).map((workOrderNumber) => {
              const { trackingNumberList } = orderTrackingMap[workOrderNumber];
              return (
                <div key={workOrderNumber} className='orderBoxWidth' style={{ border: singleOrder ? 'none' : '1px solid #909090', paddingBottom: singleOrder ? '0px' : '16px', marginBottom: singleOrder ? '0px' : '16px' }}>
                  {!singleOrder && (<div className='container-fluid' style={{ display: workOrderNumber === 'Not Available' ? 'none' : 'block' }}>
                    <h4 style={{ alignSelf: 'flex-start' }} className="text mt-3">
                      Order Number
                    </h4>
                    <span style={{ color: '#333333' }}>{workOrderNumber}</span>
                  </div>
                  )}

                  {trackingNumberList.map((answer, index) => {
                    if (
                      (this.props.qa.question.subQuestionAnswers[0].question.answerType === "DROP_DOWN_NUMBER"
                        || this.props.qa.question.subQuestionAnswers[0].question.subQuestionAnswers[0].question.answerType === "DROP_DOWN_NUMBER")
                      && answer.haulAvailable) {
                      this.trackingNumbers.push(answer.trackingNumber);
                      return (
                        <div ref={this.errorRef}>
                          <NumberDropDown
                            trackingNumber={answer.answer.trackingNumber}
                            qa={this.props.qa.question.subQuestionAnswers[0]}
                            errorIDs={this.props.errorIDs}
                            clearErrorId={this.props.clearErrorId}
                            errorTrackingNumbers={this.props.errorTrackingNumbers}
                          />
                        </div>
                      )
                    }
                    this.trackingNumbers.push(answer.answer.trackingNumber);
                    const value = answer.answer.currentValue === true || answer.answer.currentValue === 'true';
                    return (
                      <div >
                        {(<div className="container-fluid mt-3">
                          <div className="row">
                            <div className={"col checkbox-row " + (value ? "checked" : "unchecked")}>
                              <Stack
                                direction="row"
                                justifyContent="left"
                                alignItems="center"
                                spacing={{ xs: 0, sm: 2, md: 2, lg: 2 }}
                              >
                                <div>
                                  <input
                                    id={"check_" + (index + 1)}
                                    ref={this.errorRef}
                                    style={{ verticalAlign: 'bottom' }}
                                    className="largeCheckbox"
                                    type="checkbox"
                                    checked={value}
                                    onChange={e =>
                                      this.handleCheckBoxChange(answer.answer.trackingNumber, e)}
                                  />
                                </div>
                                <div>
                                  <label for={"check_" + (index + 1)}>
                                    <span>Tracking Number&nbsp;</span><br role="presentation" />
                                    <span>{answer.answer.trackingNumber}</span>
                                  </label>                                  
                                </div>
                              </Stack>
                            </div>
                          </div>
                        </div>)}
                        <div key={index + 1}>
                          {answer.answer.currentValue === true && this.getItems(this.props.qa.question.subQuestionAnswers[0].question.subQuestionAnswers[0].answer).map(
                            (childAnswer, index) => {
                              if (childAnswer.answer.trackingNumber === answer.answer.trackingNumber
                                && childAnswer.haulAvailable
                              ) {
                                return (
                                  <div key={index} className="mt-3" ref={this.errorRef}>
                                    <NumberDropDown
                                      inputRef={this.errorRef}
                                      trackingNumber={answer.answer.trackingNumber}
                                      qa={this.props.qa.question.subQuestionAnswers[0].question.subQuestionAnswers[0]}
                                      errorIDs={this.props.errorIDs}
                                      clearErrorId={this.props.clearErrorId}
                                      errorTrackingNumbers={this.props.errorTrackingNumbers}
                                    />
                                  </div>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    );
                  })
                  }
                </div>
              )
            })
          )}
        </fieldset>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        updateData,
        getMessageToShow
      },
      dispatch
    )
  };
};

export default connect(null, mapDispatchToProps)(HaulAway);
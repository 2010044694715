import { apiRepo } from 'repository/apiRepo';
export const TRANSACTION_DETAIL = 'transaction_detail';
export const SCHEDULE_DELIVERY = 'schedule_delivery';
export const SCHEDULE_INCORRECT_INFO = 'schedule_incorrect_info';
export const STORE_DATE = 'store_date';
export const CREATE_SCHEDULE_DETAILS = 'create_schedule_details';
export const GET_ORDER = 'get_order';
export const FLASH_MESSAGE = 'getMessageToShow';
export const VALIDATED_ORDER = 'validated_order';
export const UPDATE_DATA = 'UPDATE_DATA';
export const ORDER_SERVICE_MAINTENANCE_ALERT ='getOrderServiceMaintenanceAlert';
export const BANNER_MESSAGE_ALERT = 'BANNER_MESSAGE_ALERT';
export const MAINTENANCE_ALERT_MESSAGE = 'showMaintenanceAlertMessage';
export const TRACKING_SERVICE_MAINTENANCE_ALERT = 'getTrackingServiceMaintenanceAlert';
export const FETCH_NEW_DATA = 'FETCH_NEW_DATA';
export const UTC_TIME_FETCH = 'UTC_TIME_FETCH';
export const TIME_WINDOWS = 'TIME_WINDOW_FETCH';

export function updateData(answer, questionId, semanticType) {
  return {
    type: UPDATE_DATA,
    payload: { answer, questionId, semanticType }
  };
}

export function getRecaptchaResponse(parm) {
  const request = apiRepo.serviceCallV2(
    'post',
    `/api/verifyRecaptcha/`,
    'track',
    parm
  );
  return {
    type: VALIDATED_ORDER,
    payload: request
  };
}

export function sendQuote(parm) {
  const request = apiRepo.serviceCallV2(
    'post',
    `/api/sendQuote/`,
    'track',
    parm
  );
  return {
    type: VALIDATED_ORDER,
    payload: request
  };
}
export function getTrackingDetails(parm) {
  const request = apiRepo.serviceCallV2(
    'get',
    `/api/v2/tracking/${parm}`,
    'track'
  );
  return {
    type: VALIDATED_ORDER,
    payload: request
  };
}

export function fetchNewData(trackingNumber) {
  return {
    type: FETCH_NEW_DATA,
    payload: trackingNumber
  }
}

export function validateTrackNbrForScheduling(parm1, parm2, parm3) {
  const request = apiRepo.serviceCall(
    'get',
    `/v2/schedule/${parm1.toUpperCase()}?orderKey=${parm2}&keySource=${parm3}`
  );
  return {
    type: TRANSACTION_DETAIL,
    payload: request
    };
}
//Change required for Calender page 
export function getTransactionDetails(parm1, parm2) {
  const request = apiRepo.serviceCall(
    'get',
    `/v1/schedule/orderdetails/transactionref/${parm1}?filter=${parm2}`
  );
  return {
    type: TRANSACTION_DETAIL,
    payload: request
  };
}

export function submitForSelfScheduling(values) {
  const request = apiRepo.serviceCall(
    'put',
    `/v1/schedule/confirmation/submit`,
    values
  );
  return {
    type: SCHEDULE_DELIVERY,
    payload: request
  };
}

export function getStarter(values) {
  const request = apiRepo.serviceCall('post', `/v1/schedule/starter`, values);
  return {
    type: TRANSACTION_DETAIL,
    payload: request
  };
}

export function notifyCustomerService(values) {
  const request = apiRepo.serviceCall(
    'put',
    `/v1/schedule/confirmation/sendalert`,
    values
  );
  return {
    type: SCHEDULE_INCORRECT_INFO,
    payload: request
  };
}

export function updateScheduleDetails(values) {
  const request = apiRepo.serviceCall(
    'put',
    `/v1/schedule/deliverydate`,
    values
  );
  return {
    type: STORE_DATE,
    payload: request
  };
}

export function createScheduleDetails(values) {
  const request = apiRepo.serviceCall(
    'post',
    `/v1/schedule/deliverydate`,
    values
  );
  return {
    type: CREATE_SCHEDULE_DETAILS,
    payload: request
  };
}

// export function getOrderDetails(parm) {
//   const request = apiRepo.serviceCall('get', `/v1/schedule/getorder/${parm}`);
//   return {
//     type: GET_ORDER,
//     payload: request
//   };
// }

export function getMessageToShow(values) {
  return {
    type: FLASH_MESSAGE,
    payload: values
  };
}

export function getOrderServiceMaintenanceAlert(parm) {
  const request = apiRepo.maintenanceServiceCall(
    'get',
    `/api/v1/scheduler/maintenance`
  );
  return {
    type: ORDER_SERVICE_MAINTENANCE_ALERT,
    payload: request
  };
}

export function getBannerMaintenanceAlert(parm) {
  const request = apiRepo.maintenanceServiceCall(
    'get',
    `/api/v1/scheduler/maintenance`
  );
  return {
    type: BANNER_MESSAGE_ALERT,
    payload: request
  };
}

export function getTrackingServiceMaintenanceAlert(parm) {
  const request = apiRepo.maintenanceServiceCall(
    'get',
    `/api/v1/tracking/maintenance`
  );
  return {
    type: TRACKING_SERVICE_MAINTENANCE_ALERT,
    payload: request
  };
}

export function showMaintenanceAlertMessage(values) {
  return {
    type: MAINTENANCE_ALERT_MESSAGE,
    payload: values
  };
}

export function getUTCTime() {
  const request = apiRepo.utcTimeServiceCall(
    'get',
    '/api/v1/utc'
  );
  return {
    type: UTC_TIME_FETCH,
    payload: request
  }
}

export function getTimeWindows(values) {
  const request = apiRepo.serviceCall(
    'post',
    `/v1/schedule/getTimeWindows`,
    values
  );
  return {
    type: TIME_WINDOWS,
    payload: request
    };
}

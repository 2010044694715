import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const TimerExpirationModal = ({
  showModal,
  timeOutType,
  startingTime,
  handleClose
}) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{timeOutType.charAt(0).toUpperCase() + timeOutType.slice(1).toLowerCase()} Reminder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Scheduling started at {startingTime} and has reached the 3 minutes limit.  Return to the start of the scheduling process.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

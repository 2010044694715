import { ORDER_SERVICE_MAINTENANCE_ALERT, BANNER_MESSAGE_ALERT, TRACKING_SERVICE_MAINTENANCE_ALERT } from 'actions';


export default function (state = { maintenanceMessage: {}, bannerMessage: {}, disabled: false }, action) {
    switch (action.type) {
        case ORDER_SERVICE_MAINTENANCE_ALERT: {
            if (action &&
                action.payload &&
                action.payload.data &&
                action.payload.data.serviceResponse &&
                action.payload.data.serviceResponse.alertMessageResponse &&
                action.payload.data.serviceResponse.alertMessageResponse.messages) {
                const alertMsg = action.payload.data.serviceResponse.alertMessageResponse.messages;
                const maintenanceMessage = {
                    alertType: 'Maintenance',
                    message: alertMsg['scheduler.maintenance.message']
                };
                const bannerMessage = {
                    alertType: 'Maintenance',
                    message: alertMsg['scheduler.maintenance.bannermessage']
                };
                const disabled = alertMsg['scheduler.alert.action.disable'] === 'true';
                return { maintenanceMessage, bannerMessage, disabled };
            } else {
                return state;
            }
        }

        case BANNER_MESSAGE_ALERT: {
            let bannerAlertBannerMessage = (action &&
                action.payload &&
                action.payload.data &&
                action.payload.data.serviceResponse &&
                action.payload.data.serviceResponse.alertMessageResponse &&
                action.payload.data.serviceResponse.alertMessageResponse.messages['scheduler.maintenance.bannermessage']) ?
                action.payload.data.serviceResponse.alertMessageResponse.messages['scheduler.maintenance.bannermessage'] : undefined;
            let maintenanceOrderAlertBannerMessageObject = {
                alertType: 'Maintenance',
                message: bannerAlertBannerMessage
            };
            return { ...state, bannerMessage: maintenanceOrderAlertBannerMessageObject };
        }
        case TRACKING_SERVICE_MAINTENANCE_ALERT: {
            if (action &&
                action.payload &&
                action.payload.data &&
                action.payload.data.serviceResponse &&
                action.payload.data.serviceResponse.alertMessageResponse &&
                action.payload.data.serviceResponse.alertMessageResponse.messages) {
                const alertMsg = action.payload.data.serviceResponse.alertMessageResponse.messages;
                const maintenanceMessage = {
                    alertType: 'Maintenance',
                    message: alertMsg['tracking.maintenance.message']
                };
                const bannerMessage = {
                    alertType: 'Maintenance',
                    message: alertMsg['tracking.maintenance.bannermessage']
                };
                const trackingdisabled = alertMsg['tracking.alert.action.disable'] === 'true';
                return { maintenanceMessage, bannerMessage, trackingdisabled };
            } else {
                return state;
            }
        }
        default:
            return state;
    }
}

import React, { Component } from 'react';
import Spinner from 'images/loader-Transparent.gif';

var style = { paddingTop: '5px' };
var textStyle = { color: '#333', fontSize: '16px' };

export class TrackingSpinner extends Component {
  render() {
    const displayMsg = this.props.displayMsg || 'Loading';
    return (
      <div className="sweet-loading" align="center" style={style}>
        <img src={Spinner} className="img-fluid" alt="spinner" style={{ width: '40px', height: '40px'}}/>
        <div style={textStyle}>{displayMsg}</div>
      </div>
    );
  }
}

export default TrackingSpinner;

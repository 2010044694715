import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'styles/self_scheduler/_schedulerLanding.scss';
import TextFieldComponent from '@mui/material/TextField';
import ButtonComponent from './common/ButtonComponent.js';
import { bindActionCreators } from 'redux';
import { getMessageToShow, getOrderServiceMaintenanceAlert, validateTrackNbrForScheduling, getStarter, getUTCTime } from 'actions/index';
import { clearSessionStorage, RegexMatch, setSessionStorage, encryptedString, decryptedString } from 'containers/common/innovel_util';
import { TrackingSpinner } from 'containers/common/tracking_spinner';
import InnovelServiceAlert from 'containers/common/innovel_service_alert.js';
import parse from 'html-react-parser';

class UserSelfSchedule extends Component {
  constructor(props) {
    super(props);
    this.topReference = React.createRef();
    this.state = {
      paramValid: true,
      trackingNumber: 0,
      validNumber: true,
      errorMsg: '',
      transactionDetails: null,
      validurl: [],
      disableSubmission: false,
      orderDelivered: false,
      trackingDelivered: false,
      errorState: false,
      alertActive: false,
      disableBtn: false,
      validPhn: true,
      phnErrorMsg: '',
      secKey: process.env.REACT_APP_SECRET
    };
    this.trackRef = React.createRef();
    this.phnRef = React.createRef();
  }

  getUrlParameter = name => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  componentDidMount() {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    localStorage.removeItem('bulkUrl');
    document.title = "Schedule My Delivery or Return Pickup | Costco Logistics"

    this.topReference.current.scrollIntoView();
    clearSessionStorage();
    this.setState({ paramValid: true });
    var url = window.location.href.split('?');
    if (url.length > 1 && url[1] !== '') {
      var param = url[1].split('=');
      switch (param[0]) {
        case 'timeout':
          localStorage.removeItem("key");
          localStorage.removeItem("source");
          this.props.getOrderServiceMaintenanceAlert();
          url.forEach(part => {
            this.state.validurl.push(part)
          });
          this.setState({ paramValid: false });
          if (param[1]?.toUpperCase() === "TRUE") {
            this.displayErrorMessage("success", "You have been brought to the start of the scheduling process due to inactivity.")
          }
          break;
        case 'timeoutWindow':
          localStorage.removeItem("key");
          localStorage.removeItem("source");
          this.props.getOrderServiceMaintenanceAlert();
          url.forEach(part => {
            this.state.validurl.push(part)
          });
          this.setState({ paramValid: false });
          if (param[1]?.toUpperCase() === "TRUE") {
            this.displayErrorMessage("success", "You have been brought to the start of the scheduling process. Scheduling must be completed within 3 minutes of being started.")
          }
          break;
        case 'order':  
          const t = this.getUrlParameter('order');
          const s = localStorage.getItem("source");     
          const k = localStorage.getItem("key") ? JSON.parse(decryptedString(localStorage.getItem("key"), this.state.secKey)) : "";
          localStorage.removeItem("key");      
          if ((t !== '' || t !== null || t !== undefined) && (s !== "tracking") && RegexMatch(t, 1)) {
            //this.setState({ validurl: url });   
            url.forEach(part => {
              this.state.validurl.push(part)
            });
            this.state.trackingNumber = t;
            this.props.getOrderServiceMaintenanceAlert().then(result => {
              var response = result.payload.data;
              if (result.payload.status === 200) {
                let alertActive = (response?.serviceResponse?.alertMessageResponse?.messages["scheduler.alert.action.disable"] === "true"
                  && response?.serviceResponse?.alertMessageResponse?.messages["scheduler.maintenance.message"] !== "") ? true : false;

                if (alertActive === "false" || alertActive === false) {                  
                  this.validateTrackOnLoad(t, k);
                } else {
                  window.location.replace("/");
                }
              }
              else {
                this.validateTrackOnLoad(t, k);
              }
            }).catch(error => {
              this.validateTrackOnLoad(t, k);
            });
          } else {
            this.state.validurl.push(url);
            if(s && s === "tracking") {
              this.setState({ paramValid: true, errorState: false, inputValue: t, trackingNumber: t, validNumber: true, errorMsg: '' });
              this.phnRef.current.focus();              
            } else {
              this.setState({ paramValid: false, errorState: true, inputValue: t, validNumber: false, validPhn: false, errorMsg: 'Enter a valid tracking or order number.' });
              this.displayErrorMessage("error", process.env.REACT_APP_MSC_CONTACT_US);
            }
          }
          break;
      }
    }
    else {
      localStorage.removeItem("key");
      localStorage.removeItem("source");
      this.props.getOrderServiceMaintenanceAlert();
      this.state.validurl.push(url);
    }
  }

  validateTrackOnLoad = (t, k) => {
    if(k?.orderKey && k?.orderKey !== undefined && k?.orderKey !== null) {
      this.getStarted(this.state.trackingNumber, k?.orderKey, k?.keySource);
    } else {
      this.setState({ disableBtn: false, paramValid: false, errorState: false });
      if (!this.state.paramValid) {
        this.state.inputValue = t;
      }
      this.setState({ disableBtn: false })
      this.props.history.replace("userselfschedule?order=" + t);
      this.displayErrorMessage("success", process.env.REACT_APP_PHONE_NUMBER_INPUT);
      this.phnRef.current.focus(); 
    }
  }


  handleInputChange = e => {
    const value = e.target.value.trim();
    this.setState({
      inputValue: value,
    });
    if (value && RegexMatch(value, 1)) {
      this.setState({ trackingNumber: value, validNumber: true, errorMsg: '', showErrorMessage: false });
    } else {
      this.setState({ validNumber: false, errorMsg: 'Enter a valid tracking or order number.', showErrorMessage: false });
    }
  };

  handleInputError = e => {
    const value = e.target.value.trim();
    
    if (value && RegexMatch(value, 1) && !this.state.validPhn) {
      this.setState({ inputValue: value });
      this.phnRef.current.focus();
    }
  }

  handlePhoneInputChange = e => {
    const value = e.target.value.trim();
    this.setState({ phnInputValue: value });
    if(value?.length === 4 && RegexMatch(value, 3)) {
      this.setState({ validPhn: true, phnErrorMsg: '' });
    } else {
      this.setState({ validPhn: false, phnErrorMsg: 'Enter valid last 4 digits of phone number.' });
    }
  }

  handlePhoneInputError = e => {
    const value = e.target.value.trim();
    
    if(value && RegexMatch(value, 3) && !this.state.validNumber) {
      this.setState({ phnInputValue: value });
      this.trackRef.current.focus();
    }
  }

  handleSubmitButtonClick = () => {
    if (this.state.disableBtn) {
      return
    }
    this.state.disableBtn = true;

    this.setState({ showErrorMessage: false });
    if (this.state.inputValue !== undefined && this.state.validNumber === true && this.state.phnInputValue !== undefined && this.state.validPhn === true) {
      this.props.history.replace("userselfschedule");
      localStorage.removeItem("source");
      this.setState(
        {
          loading: true,
          orderList: [],
          showErrorMessage: false,
          errorMessage: ''
        });
      this.props.getUTCTime().then(result => {
        const httpStatus = result.payload.status
        if (httpStatus === 200) {
          //var response = result.payload.data;
          sessionStorage.setItem("utcTime", result.payload.request.response)
        }
        else {
          sessionStorage.removeItem("utcTime");
        }
        this.getStarted(this.state.trackingNumber, this.state.phnInputValue, process.env.REACT_APP_PORTAL_INDICATOR);
      });
    }
    else {
      const { inputValue, phnInputValue, validNumber, validPhn } = this.state;
      const trackingValid = inputValue && validNumber ? true : false;
      const phoneValid = phnInputValue && validPhn ? true : false;

      if (!trackingValid) {
        this.trackRef.current.focus();
      } else if (!phoneValid) {
        this.phnRef.current.focus();
      }

      this.setState({ 
        disableBtn: false, 
        validNumber: trackingValid, 
        errorMsg: trackingValid === false ? 'Enter a valid tracking or order number.' : '', 
        validPhn: phoneValid, 
        phnErrorMsg: phoneValid === false ? 'Enter valid last 4 digits of phone number.' : '',
        showErrorMessage: false });
    }
  };

  getStarted = (trackingNum, phoneNum, keySource) => {
    this.setState({ loading: true });
    let trackingnumber = trackingNum.toString();
     
    let phoneNumber = phoneNum?.toString();
    this.props.validateTrackNbrForScheduling(trackingnumber, phoneNumber, keySource).then(result => {
      const httpStatus = result.error === undefined ? result.payload.status : result.payload.response !== undefined ? result.payload.response.status : result.payload.request.status;
      if (httpStatus === 200 || httpStatus === 400 || httpStatus === 500) {
        // this.setState({ disableBtn: false });
        const status = result.error === undefined ? result.payload.data.status : result.payload.response.data.status;
        if (status !== null || status !== undefined) {
          const validStatusCode = status.statusCode != null ? "True" : "False";
          if (status.statusCode === '00' && validStatusCode) {
            var response = result.payload.data;
            const orders = response.serviceResponse.workOrderList;

            if (orders === null || orders === '' || orders === undefined) {
              this.setState({ paramValid: false });
              if (!this.state.paramValid) {
                this.state.inputValue = trackingnumber;
              }
              this.displayErrorMessage("error", process.env.REACT_APP_MSC_CONTACT_US);
            }
            else {
              var keyObj = JSON.stringify({'orderKey': phoneNum, 'keySource': keySource });
              var encKey = encryptedString(keyObj, this.state.secKey);
              var selfscheduleURL = '/selfschedule?order=' + trackingnumber;
              let pageToPush = '';
              const ordersMapped = response.serviceResponse.workOrderList.map(order => {
                return {
                  workOrderNumber: order.workOrderNumber,
                  trackingOrderList: order.trackingOrderList.map(item => ({ selected: true, trackingNumber: item.trackingNumber, allowToSchedule: item.allowToSchedule, primary: item.primary, noSchCode: item.noSchCode, scheduleStatus: item.scheduleStatus }))
                }
              });

              if (ordersMapped.length > 1) {
                localStorage.setItem("key", encKey.toString()); 
                this.setState({ disableBtn: false });
                pageToPush = selfscheduleURL;
                this.props.history.push(pageToPush);
              }
              else if (ordersMapped.length === 1) {
                if (ordersMapped[0].trackingOrderList.length > 1) {
                  this.setState({ disableBtn: false })
                  if (status.statusCode === '00') {
                    localStorage.setItem("key", encKey.toString());       
                    //sessionStorage.setItem('serviceResponse', JSON.stringify(response.serviceResponse));
                    pageToPush = selfscheduleURL;
                    this.props.history.push(pageToPush);
                  }
                  else {
                    //this.setState({ disableBtn: false})
                    this.displayErrorMessage("error", response.errorDetails.errors[0].message);
                  }
                }
                else if (ordersMapped[0].trackingOrderList.length === 1) {
                  if (status.statusCode === '00') {
                    if (ordersMapped[0].trackingOrderList[0].allowToSchedule && ordersMapped[0].trackingOrderList[0].noSchCode === null) {
                      pageToPush = 'tracking_schedule';
                      this.starterCall(trackingnumber, ordersMapped, pageToPush);
                    }
                    else if (!ordersMapped[0].trackingOrderList[0].allowToSchedule && ordersMapped[0].trackingOrderList[0].noSchCode !== null) {
                      this.setState({ paramValid: false, errorState: true });
                      if (!this.state.paramValid) {
                        this.state.inputValue = trackingnumber;
                      }
                      this.setState({ disableBtn: false })
                      this.displayErrorMessage("error", ordersMapped[0].trackingOrderList[0].scheduleStatus);
                    }
                    else if (!ordersMapped[0].trackingOrderList[0].allowToSchedule && ordersMapped[0].trackingOrderList[0].noSchCode === null) {
                      this.setState({ paramValid: false, errorState: true });
                      if (!this.state.paramValid) {
                        this.state.inputValue = trackingnumber;
                      }
                      this.setState({ disableBtn: false })
                      //Enabling the button
                      this.displayErrorMessage("error", ordersMapped[0].trackingOrderList[0].scheduleStatus);
                    }
                    else if (ordersMapped[0].trackingOrderList[0].allowToSchedule && ordersMapped[0].trackingOrderList[0].noSchCode !== null) {
                      this.setState({ disableBtn: false })
                      this.displayErrorMessage("error", process.env.REACT_APP_MSC_CONTACT_US);
                    }
                  }
                  else {
                    this.setState({ disableBtn: false })
                    this.displayErrorMessage("error", response.errorDetails.errors[0].message);
                  }
                }
              }
            }
          }
          else {
            this.setState({ disableBtn: false, paramValid: false, errorState: true });
            if (!this.state.paramValid) {
              this.state.inputValue = trackingnumber;
            }
            const errorMessage = result.error === undefined ? result.payload.data.errorDetails.errors[0].message : result.payload.response.data.errorDetails.errors[0].message;
            this.setState({ disableBtn: false })
            this.displayErrorMessage("error", errorMessage);
          }
        }
        else {
          this.setState({ disableBtn: false, paramValid: false, errorState: true });
          if (!this.state.paramValid) {
            this.state.inputValue = trackingnumber;
          }
          this.setState({ disableBtn: false })
          this.displayErrorMessage("error", process.env.REACT_APP_MSC_CONTACT_US);
        }
      }
      else {
        this.setState({ disableBtn: false, paramValid: false, errorState: true });
        if (!this.state.paramValid) {
          this.state.inputValue = trackingnumber;
        }
        this.setState({ disableBtn: false })
        this.displayErrorMessage("error", process.env.REACT_APP_MSC_CONTACT_US);
      }
    }).catch(error => {
      this.setState({ disableBtn: false, paramValid: false, errorState: true });
      if (!this.state.paramValid) {
        this.state.inputValue = trackingnumber;
      }
      this.setState({ disableBtn: false })
      this.displayErrorMessage("error", process.env.REACT_APP_MSC_CONTACT_US);
    });
  };

  starterCall = (trackingNum, orders, page) => {
    const payload = [];
    orders.forEach(workorder =>
      workorder.trackingOrderList.forEach(workOrderList =>
        payload.push({
          ...workOrderList,
          workOrderNumber: workorder.workOrderNumber
        })
      )
    )

    this.props.getStarter(payload).then(result => {
      this.setState({ disableBtn: false });
      const httpStatus = result.error === undefined ? result.payload.status : result.payload.response !== undefined ? result.payload.response.status : result.payload.request.status;
      if (httpStatus === 200 || httpStatus === 400 || httpStatus === 500) {
        const status = result.error === undefined ? result.payload.data.status : result.payload.response.data.status;
        if (status !== null || status !== undefined) {
          const validStatusCode = status.statusCode != null ? "True" : "False";
          if (status.statusCode === '00' && validStatusCode) {
            setSessionStorage(
              result.payload.data.serviceResponse.transactionRef,
              trackingNum
            );

            this.props.history.push(page);
          }
          else {
            this.setState({ paramValid: false, errorState: true, disableBtn: false });
            if (!this.state.paramValid) {
              this.state.inputValue = this.state.trackingNumber;
            }
            const errorMessage = result.error === undefined ? result.payload.data.errorDetails.errors[0].message : result.payload.response.data.errorDetails.errors[0].message;
            this.displayErrorMessage("error", errorMessage);
          }
        }
        else {
          this.setState({ disableBtn: false })
          this.noServiceError(process.env.REACT_APP_MSC_CONTACT_US);
        }
      }
      else {
        this.setState({ disableBtn: false })
        this.noServiceError(process.env.REACT_APP_MSC_CONTACT_US);
      }
    }).catch((error) => {
      this.setState({ disableBtn: false })
      this.noServiceError(process.env.REACT_APP_MSC_CONTACT_US);
    });
  };

  displayErrorMessage = (alertType, errorMessage) => {
    let messageObj = {
      alertType: alertType,
      message: parse(errorMessage)
    };
    this.setState({ showErrorMessage: true, errorMessage: messageObj.message });
    this.setState({ loading: false });
  };

  noServiceError = (message) => {
    this.setState({ paramValid: false, errorState: true });
    if (!this.state.paramValid) {
      this.state.inputValue = this.state.trackingNumber;
    }
    this.displayErrorMessage("error", message);
  }

  render() {
    const messObj = this.props.messageObj;
    if (this.state.validurl.length >= 2 && this.state.paramValid) {
      return (
        <div className="container-fluid main-grid" style={{ minHeight: '90vh' }} ref={this.topReference}>
          <div className="row">
            <div className="col">
              <div style={{ marginTop: '50vh' }}>
                <div className="centerAlign" style={{ flexDirection: 'column' }}>
                  {<TrackingSpinner />}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="container-fluid self-schedule-grid" role="main" ref={this.topReference}>
          <div className="row">
            <div className='col-lg-10 offset-md-1 offset-lg-2'>
              <div id="mains" className="sr-only" tabIndex="-1"></div>
              <div id="banner" aria-labelledby='banner'>
                <h1 className="t1" aria-label='Schedule My Delivery or Return Pickup.'>
                  Schedule My Delivery or Return Pickup
                </h1>
                <p className="body-copy" style={{ marginTop: '8px' }}>Costco makes it easy for you to schedule your delivery or return pickup in 3 easy steps.</p>
                <p className="body-copy">Enter your tracking number or Costco.com order number and last four digits of the phone number associated with your order to schedule (or reschedule) your delivery or return pickup.</p>
                {' '}
              </div>
            </div>
          </div>
          <div className="row">
            <div className='col-lg-10 offset-md-1 offset-lg-2 traking-ele'>
              <div style={{ float: 'left', marginBottom: '12px' }}><InnovelServiceAlert messObj={messObj} name='SCHEDULER' /></div>
            </div>
          </div>
          <div className="row">
            <div className='col-sm-12 col-md-10 col-lg-12 offset-md-1 offset-lg-2 traking-ele'>
              {this.state.showErrorMessage && !this.props.actionDisabled && (
                <div id="noSchedule" role="alert" tabIndex="-1" className={this.state.validurl.length === 1 ? "err-msg" : this.state.validurl[1].split('=')[0] && !this.state.errorState ? "alert alert-msg alert-success" : "err-msg"} style={{ float: "left", width: this.state.paramValid ? "100%" : "" }}>
                  <p>
                    {this.state.errorMessage}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 col-lg-4 offset-md-1 offset-lg-2 traking-ele">
              <TextFieldComponent
                inputRef={this.trackRef}
                fullWidth
                error={!this.state.validNumber}
                disabled={this.props.actionDisabled}
                aria-required="true"
                id="tracking-number"
                label="Tracking or Order Number"
                value={this.state.inputValue}
                variant="outlined"
                onChange={this.handleInputChange}
                onBlur={this.handleInputError}
                helperText={this.state.errorMsg}
                color="primary"
                autoComplete="off"
                inputProps={{
                  "aria-invalid": true,
                }}
              />
              <TextFieldComponent
                inputRef={this.phnRef}
                error={!this.state.validPhn}
                disabled={this.props.actionDisabled}
                aria-required="true"
                id="phone-number"
                variant="outlined"
                color="primary"
                autoComplete="off"
                label="Last 4 Digits of Phone Number"
                type="text"
                inputProps={{
                  maxLength: 4,
                  "aria-invalid": true,
                }}
                onChange={this.handlePhoneInputChange}
                onBlur={this.handlePhoneInputError}
                helperText={this.state.phnErrorMsg}
                sx={{ width: "70%"}}
              />
              <ButtonComponent
                disableRipple
                role="link"
                variant="contained"
                color="primary"
                disabled={this.props.actionDisabled}
                onClick={!this.state.disableBtn ? this.handleSubmitButtonClick : null}
                btnText={'Schedule Delivery or Return Pickup'}
              />
              {this.state.loading && (
                <TrackingSpinner />
              )}
            </div>
            <br role="presentation" />
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    messageObj: state.maintenanceAlertMessage.maintenanceMessage,
    actionDisabled: state.maintenanceAlertMessage.disabled
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getMessageToShow, getOrderServiceMaintenanceAlert, validateTrackNbrForScheduling, getStarter, getUTCTime }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSelfSchedule);

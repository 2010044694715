import React from 'react';
import Button from '@mui/material/Button';


const ButtonComponent = (props) => {

    return(
        <div>
            <Button variant={props.variant} {...props}>{props.btnText}</Button>
        </div>
    )
}

export default ButtonComponent;
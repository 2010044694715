import { SCHEDULE_DELIVERY } from 'actions';

export default function(state = [], action) {
  switch (action.type) {
    case SCHEDULE_DELIVERY:
      const httpStatus = action.error === undefined ? action.payload.status : action.payload.response !== undefined ? action.payload.response.status : action.payload.request.status;
      if (httpStatus === 200 || httpStatus === 400 || httpStatus === 500) {
        return action.payload.data.serviceResponse;
      } /*else if (
        action.payload.response === undefined ||
        action.payload.response === null
      ) {
        let toRet = { resultFound: false, time: Date.now };
        let errmsg = action.payload.message;
        toRet = {
          ...toRet,
          message: errmsg
        };
        return toRet;
      } else if (
        action.payload.response !== undefined &&
        action.payload.response !== null
      ) {
        let toRet = { resultFound: false, time: Date.now };
        var errmsgobj = action.payload.response.data.errors;
        let errmsg = errmsgobj[0].message;
        toRet = {
          ...toRet,
          message: errmsg
        };
        return toRet;
      }*/
      else {
        return { 
          serverError: true,
          loading: false,
          time: Date.now, 
          errorMessage: 'We are unable to schedule your order online. Contact us at 1-800-955-2292 to schedule your delivery.',
          trackingOrder: [],
          scheduleTransactionDetail: {},
          orderDeliveryDetails: {}
        };
      }
      break;
    default:
      return state;
  }
}

import {TIME_WINDOWS} from '../actions/index';

export default function (state = [], action) {
    switch(action.type) {
        case TIME_WINDOWS:
            if(action.payload.status === 200) {
                var statusCode = action.payload.data.status.statusCode;
                if(statusCode) {
                    var response;
                    if(statusCode === "00") {
                        response =  action.payload.data.serviceResponse;
                    } else {
                        let toRet = {resultFound:false, time: Date.now};
                        var errMsg = "Error";
                        toRet = {
                            ...toRet,
                            message: errMsg
                        }
                        return toRet;
                    }
                    
                    return response;
                } else {
                    let toRet = {resultFound:false, time: Date.now};
                    var errMsg = "Error: No Data Found.";
                    toRet = {
                        ...toRet,
                        message: errMsg
                    }
                    return toRet;
                    //return action.payload.data;
                }
            } else if (action.payload.response === undefined || action.payload.response === null){
                let toRet = {resultFound:false, time: Date.now};
                var errMsg = action.payload.message;
                toRet = {
                    ...toRet,
                    message: errMsg
                }
                return toRet;
            } else if((action.payload.response !== undefined || action.payload.response !== null) && action.payload.response.status === 400){
                let toRet = {resultFound: false, time: Date.now};
                var errMsgObj = action.payload.response.data.errorDetails.errors;
                var errMsg = errMsgObj[0].message;
                toRet = {
                    ...toRet,
                    message: errMsg
                }
                return toRet;
            } else {
                return { 
                    serverError: true,
                    loading: false,
                    time: Date.now, 
                    errorMessage: 'An error occured, try again later.',
                    trackingOrder: [],
                    scheduleTransactionDetail: {},
                    orderDeliveryDetails: {}
                  };
            }
        default:
            return state;
    }
}
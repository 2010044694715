import _ from 'lodash';
import { TRANSACTION_DETAIL, FETCH_NEW_DATA } from 'actions';
import { UPDATE_DATA } from 'actions';
import { updateQA, updateReplacementQA } from './formFunctions';
import parse from 'html-react-parser';

/**
 * masterQA object will store the initial transactionDetails.questionAnswer.questionAnswer object.
 * In the code we're going to modify the questionAnswer objecy many times, so while submitting the form,
 * we will need all the QA back in orderDeliveryDetails. For that, we'll make an immutable copy in masterQA.
 * Also, never mutate the masterQA in any place.
 *  */

export default function(state = {}, action) {
  let qa = {...state.questionAnswer};
  switch (action.type) {
    case FETCH_NEW_DATA:
      return {...state, loading: true, trackingNumber: action.payload};

    case TRANSACTION_DETAIL:
      if ((action.payload != undefined || action.payload != null) && 
      (action.payload.status === 200 || action.payload.status === 400 || action.payload.status === 500)) {
        const statusCode = action.error === undefined ? action.payload.data.status.statusCode : action.payload.response.data.status.statusCode;
        if (statusCode !== '00') {
          return { 
            serverError: true,
            loading: false,
            time: Date.now,
            errorMessage: action.payload.data.errorDetails.errors[0].message,
            trackingOrder: [],
            scheduleTransactionDetail: {},
            orderDeliveryDetails: {}
          };
        } else if (statusCode === '00') {
          qa = action.payload.data.serviceResponse.questionAnswer
          const masterQA =  action.payload.data.serviceResponse
            && action.payload.data.serviceResponse.questionAnswer
            && action.payload.data.serviceResponse.questionAnswer.questionAnswer 
            && _.cloneDeep(action.payload.data.serviceResponse.questionAnswer.questionAnswer);
          // To remove the Haul_away subQA choices run the updateReplacementQA
          if (qa && qa.questionAnswer) {
            const queReplacement = qa.questionAnswer.find(q => q.question.semanticType === 'REPLACEMENT');
            if(queReplacement) {
              qa.questionAnswer = updateReplacementQA(qa.questionAnswer, masterQA, 'REPLACEMENT', queReplacement.answer.newValue);
            }
          }
          return {
            ...action.payload.data.serviceResponse,
            loading: false,
            questionAnswer: _.cloneDeep(qa),
            masterQA
            };
        } else if (
          action.payload.response === undefined ||
          action.payload.response === null
        ) {
          let toRet = { resultFound: false, time: Date.now };
          let errmsg = action.payload.message;
          toRet = {
            ...toRet,
            loading: false,
            message: errmsg
          };
          return toRet;
        } else if (
          action.payload.response !== undefined &&
          action.payload.response !== null
        ) {
          let toRet = { resultFound: false, time: Date.now };
          var errmsgobj = action.payload.response.data.errors;
          let errmsg = errmsgobj[0].message;
          toRet = {
            ...toRet,
            laoding: false,
            message: errmsg
          };
          return toRet;
        }
      }
      else {
        return { 
          serverError: true,
          loading: false,
          time: Date.now, 
          errorMessage: process.env.REACT_APP_MSC_CONTACT_US,
          trackingOrder: [],
          scheduleTransactionDetail: {},
          orderDeliveryDetails: {}
        };
      }  
      break;

    case UPDATE_DATA:
      updateQA(
        qa.questionAnswer,
        action.payload.questionId,
        action.payload.answer
      );
      qa.questionAnswer = updateReplacementQA(
        qa.questionAnswer,
        state.masterQA,
        action.payload.semanticType,
        action.payload.answer
      );
      return { ...state, questionAnswer: qa };

    default:
      return state;
  }
}

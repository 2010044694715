const _ = require('lodash');

export const updateQA = (qa, qid, ans) => {
  qa.map(q => {
    if (q.question.id === qid) {
      if (q.question.answerType === 'CHECK_BOX' || q.question.answerType === 'DROP_DOWN_NUMBER') {
        q.answer = ans;
      } else {
        q.answer.newValue = ans;
      }
    }
    if (
      q.question.subQuestionAnswers &&
      q.question.subQuestionAnswers.length > 0
    ) {
      updateQA(q.question.subQuestionAnswers, qid, ans);
    }
    return q;
  })
}

export function getItems(items) {
  if (Array.isArray(items))
    return items;
  else
    return [items];
}

export function changeNumberDropDown(QuestionAnswer, answer, semanticType) {
  QuestionAnswer.map(qa => {
    if (qa.question.semanticType === semanticType) {
      qa.answer = answer;
    }
    if (
      qa.question.subQuestionAnswers &&
      qa.question.subQuestionAnswers.length > 0
    ) {
      changeNumberDropDown(
        qa.question.subQuestionAnswers,
        answer,
        semanticType
      );
    }
    return qa;
  });
}

/*
  8: REPLACEMENT
  9: REPLACEMENT
  10: REPLACEMENT_ITEM
  11: HAUL_AWAY
  12: HAUL_AWAY
*/
export function updateReplacementQA(questions, masterQA, semanticType, userAnswer) {
  let QuestionAnswer = [...questions];
  // If semanticType is REPLACEMENT and there is no subQuestion, remove question HAUL_AWAY
  // If semanticType is REPLACEMENT and have subQuestion semanticType REPLACEMENT_ITEM, and all the options checked for Q REPLACEMENT_ITEM, remove Q HAUL_AWAY. 
  if (semanticType === 'REPLACEMENT' || semanticType === 'REPLACEMENT_ITEM') {
    const queReplacement = QuestionAnswer.find(qa => qa.question.semanticType === 'REPLACEMENT');
    let queHaulAway = QuestionAnswer.find(qa => qa.question.semanticType === 'HAUL_AWAY');
    const masterQueHA = masterQA.find(qa => qa.question.semanticType === 'HAUL_AWAY');
    const masterHASubAnswer= masterQueHA 
      && masterQueHA.question 
      && masterQueHA.question.subQuestionAnswers 
      && masterQueHA.question.subQuestionAnswers[0]
      && masterQueHA.question.subQuestionAnswers[0].answer;
    if (userAnswer || semanticType === 'REPLACEMENT_ITEM') {
      const subQA = queReplacement && queReplacement.question && queReplacement.question.subQuestionAnswers && queReplacement.question.subQuestionAnswers[0];
      if (!subQA) {
        QuestionAnswer = QuestionAnswer.filter(qa => qa.question.semanticType !== 'HAUL_AWAY');
      } else {
        const unselectedAns = subQA.answer.filter(ans => !ans.newValue);
        if (!_compareUnselectedAns(unselectedAns, masterHASubAnswer)) {
          QuestionAnswer = QuestionAnswer.filter(qa => qa.question.semanticType !== 'HAUL_AWAY');
        } else if (unselectedAns && unselectedAns.length) {
          if (unselectedAns.length === queReplacement.answer.length) {
            // unselect Replacement radio answer
            QuestionAnswer = QuestionAnswer.filter(qa => qa.question.semanticType !== 'REPLACEMENT');
            let queReplacementNew = _.cloneDeep(masterQA.find(qa => qa.question.semanticType === 'REPLACEMENT'));
            queReplacementNew.answer.newValue = false
            QuestionAnswer.push(queReplacementNew)
          }
          if (!queHaulAway) {
            queHaulAway = _.cloneDeep(masterQA.find(qa => qa.question.semanticType === 'HAUL_AWAY'));
            QuestionAnswer.push(queHaulAway);
          }
          if (queHaulAway.question
            && queHaulAway.question.subQuestionAnswers
            && queHaulAway.question.subQuestionAnswers[0]
          ) {
            queHaulAway.question.subQuestionAnswers[0].answer = _generateHaulAwayCheckBox(queHaulAway, unselectedAns, masterHASubAnswer);
            QuestionAnswer = QuestionAnswer.filter(qa => qa.question.semanticType !== 'HAUL_AWAY');
            QuestionAnswer.push(queHaulAway);
          }
        }
      }
    } else {
      if (!queHaulAway) {
        queHaulAway = masterQA.find(qa => qa.question.semanticType === 'HAUL_AWAY');
        QuestionAnswer.push(queHaulAway);
      }
      if (semanticType === 'REPLACEMENT' && !userAnswer) {
        const subQA = queReplacement && queReplacement.question && queReplacement.question.subQuestionAnswers && queReplacement.question.subQuestionAnswers[0];
        if (subQA) {
          subQA.answer = subQA.answer.map(ans => { return { ...ans, newValue: false } });
          queHaulAway.question.subQuestionAnswers[0].answer = _generateHaulAwayCheckBox(queHaulAway, subQA.answer, masterHASubAnswer);
        }
      }
    }
  }
  return QuestionAnswer;
}

/* *
 * compare replacement unselectedAns with haulaway masterAns object. If there's any unselected answer with the traching number
 * same as masterAns, then we will return true and display haul_away question.
 */
const _compareUnselectedAns = (answerRep, masterAnsHA) => {
  let result = false;
  if (!answerRep || (answerRep && !answerRep.length)) {
    return result;
  }
  masterAnsHA.forEach(ans => {
    const index = answerRep.findIndex(x => x.trackingNumber === ans.answer.trackingNumber);
      if (index !== -1) {
        result = true;
      }
  })
  return result;
}

// Create checkbox list for haul_away questions based on replacement unselected checkboxes.
// For the first load, keep the newValue same as masterQA, else false
const _generateHaulAwayCheckBox = (queHaulAway, unselectedAnsRep, masterHaulAwayAns) => {
  const haulawayCheckboxAns = [];
  const currentHaulAwayCheckboxAns = queHaulAway.question.subQuestionAnswers[0].answer;
  unselectedAnsRep.forEach(ans => {
    const index = currentHaulAwayCheckboxAns.findIndex(x => x.answer.trackingNumber === ans.trackingNumber);
      if (index !== -1) {
        haulawayCheckboxAns.push({ ...currentHaulAwayCheckboxAns[index] });
      } else {
        const masterAns = masterHaulAwayAns.find(x =>  x.answer.trackingNumber === ans.trackingNumber);
        masterAns && haulawayCheckboxAns.push({ ...masterAns });
      }
  })
  return haulawayCheckboxAns;
}